import { useEffect, useState } from "react";
import useSignalR from "./useSignalR";
import { HubConnectionState } from "@microsoft/signalr";
import { IOrderChanged, WebSocketEvents } from "src/api/types/orders";
import {
  IIdleRiderItem,
  IUnreachableRidertem,
  RiderWorkingStatusItem,
} from "src/api/types/riders";
import { panelDashboardService } from "src/api/services/dashboard";
import { useGlobal } from "../global-context/GlobalContext";

export function useDashboardRiderList(activeTabIndex: number) {
  const [allowToCallAPI, setAlreadyFetchedAPI] = useState<boolean>(false);
  const [idleloading, setIdleLoading] = useState<boolean>(false);
  const [unreachableLoading, setUnreachableLoading] = useState<boolean>(false);
  const [idleRiders, setIdleRiders] = useState<IIdleRiderItem[]>([]);
  const [unreachableRiders, setUnreachableRiders] = useState<
    IUnreachableRidertem[]
  >([]);
  const [abortController] = useState<AbortController>(new AbortController());
  const [idleNotFound, setIdleNotFound] = useState<boolean>(false);
  const [unreachableNotFound, setUnreachableNotFound] =
    useState<boolean>(false);
  const { updateStatistics } = useGlobal();

  const { connection, connectionStatus } = useSignalR({
    urlPath: "hub/notificationhub",
  });

  useEffect(() => {
    if (connectionStatus === HubConnectionState.Connected) {
      setAlreadyFetchedAPI(true);
      return () => {
        setAlreadyFetchedAPI(false);
      };
    }
  }, [connectionStatus]);

  const handleIdleRidersEvent = () => {
    connection?.on(WebSocketEvents.IdleRider, (item: string) => {
      const riderItem: IIdleRiderItem = JSON.parse(item);
      if (riderItem.isIdle) {
        if (!idleRiders.find((item) => item.riderId === riderItem.riderId)) {
          setIdleRiders((oldArray) => [...oldArray, riderItem]);
        }
      } else {
        setIdleRiders((items) =>
          items.filter((item) => item.riderId !== riderItem.riderId)
        );
      }
    });
  };

  const handUnreachableRidersEvent = () => {
    connection?.on(WebSocketEvents.UnreachableRider, (item: string) => {
      const unRiderItem: IUnreachableRidertem = JSON.parse(item);
      if (unRiderItem.isUnreachable) {
        if (
          !unreachableRiders.find(
            (item) => item.riderId === unRiderItem.riderId
          )
        ) {
          setUnreachableRiders((oldArray) => [...oldArray, unRiderItem]);
        }
      } else {
        setUnreachableRiders((items) =>
          items.filter((item) => item.riderId !== unRiderItem.riderId)
        );
      }
    });
  };

  const handleRiderWorkingStatusEvents = () => {
    connection?.on(WebSocketEvents.RiderWorkingStatus, (item: string) => {
      const riderItem: RiderWorkingStatusItem = JSON.parse(item);
      if (riderItem.workingStatusTitle) {
        setIdleRiders((idleRiders) =>
          idleRiders.map((idleRider) =>
            idleRider.riderId === riderItem.riderId
              ? {
                  ...idleRider,
                  workingStatusTitle: riderItem.workingStatusTitle,
                  workingStatusId: riderItem.workingStatusId,
                }
              : idleRider
          )
        );
        return;
      }
    });
  };

  const handleOrderDeliveredCount = () => {
    connection?.on(WebSocketEvents.OrderDeliveredCount, (order: string) => {
      const orderDeliverCount: { count: number } = JSON.parse(order);

      updateStatistics({
        totalDeliveredOrdersCount: orderDeliverCount.count,
      });
    });
  };

  useEffect(() => {
    if (connection) {
      handleIdleRidersEvent();
      handUnreachableRidersEvent();
      handleRiderWorkingStatusEvents();
      handleOrderDeliveredCount();
    }
  }, [connection]);

  const getIdleRiders = async () => {
    setIdleLoading(true);
    try {
      const result = await panelDashboardService.getIdleRiders(
        abortController.signal
      );
      setIdleRiders(result.data.data.items);
      result.data.data.items.length > 0
        ? setIdleNotFound(false)
        : setIdleNotFound(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIdleLoading(false);
    }
  };

  const getUnreachableRiders = async () => {
    setUnreachableLoading(true);
    try {
      const result = await panelDashboardService.getUnreachableRiders(
        abortController.signal
      );
      setUnreachableRiders(result.data.data.items);
      result.data.data.items.length > 0
        ? setUnreachableNotFound(false)
        : setUnreachableNotFound(true);
    } catch (error) {
      console.log(error);
    } finally {
      setUnreachableLoading(false);
    }
  };

  useEffect(() => {
    idleRiders.length > 0 ? setIdleNotFound(false) : setIdleNotFound(true);
  }, [idleRiders.length]);

  useEffect(() => {
    unreachableRiders.length > 0
      ? setUnreachableNotFound(false)
      : setUnreachableNotFound(true);
  }, [unreachableRiders.length]);

  useEffect(() => {
    console.log("---index ----", activeTabIndex);
    if (allowToCallAPI) {
      switch (activeTabIndex) {
        case 0:
          getIdleRiders();
          return () => {
            setIdleLoading(false);
          };
        case 1:
          getUnreachableRiders();
          return () => {
            setUnreachableLoading(false);
          };
      }
    }
  }, [allowToCallAPI, activeTabIndex]);

  return {
    idleloading,
    unreachableLoading,
    idleNotFound,
    setIdleNotFound,
    unreachableNotFound,
    setUnreachableNotFound,
    idleRiders,
    unreachableRiders,
  };
}
