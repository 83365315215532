import { API_ROUTES } from 'src/constants/api-routes'
import { AxiosInstance } from '..'
import { IAxiosResponse } from '../types/axios-response'
import { IFeedbackDetailsPayload, IFeedbackListPayload } from '../types/feedback'

export const panelFeedbackService = {
  getAll(
    offset: number,
    count: number,
    sort: {
      colId: string
      sort: string | 'asc' | 'desc'
    }[] = [],
    abortSignal: AbortSignal,
    searchQuery: string = '',
    filter: {
      colId: string
      filter: string
    }[] = []
  ) {
    let keyword = searchQuery ? `&keyword=${searchQuery}` : ''
    let sortKey = sort.length > 0 ? `&` : ''
    sort.forEach(item => {
      if (!item.sort) {
        return
      }
      const sortValue = item.sort === 'asc' ? 'Ascending' : 'Descending'
      sortKey += `&sort_${item.colId}=${sortValue}`
    })
    let filterKey = filter.length > 0 ? `&` : ''
    filter.forEach(item => {
      if (!item.filter) {
        return
      }
      const filterValue = item.filter.toString()
      filterKey += `&filter_${item.colId}=${filterValue}`
    })
    return AxiosInstance.get<IAxiosResponse<IFeedbackListPayload>>(
      `${API_ROUTES.FEEDBACK.ROOT}?count=${count}&offset=${offset}${sortKey}${filterKey}${keyword}`,
      { signal: abortSignal }
    )
  },
  get(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IFeedbackDetailsPayload>>(
      `${API_ROUTES.FEEDBACK.ROOT}/${id}`,
      {
        signal: abortSignal
      }
    )
  }
}
