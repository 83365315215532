import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import DatePicker from "react-datepicker";
// todo: fix ASAP
import "src/pages/dashboard/Reports/AmericanaOrdersReport/react-datepicker.css";
import { API_ROUTES } from "src/constants/api-routes";
import dateHelper from "src/utils/date-helper";
import { BulkUploadBox } from "../../../../components/app/BulkUpload/BulkUploadBox";

const RidersWorkScheduleReport: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.ridersWorkScheduleReport"));

  return (
    <Box className={"upload-box-page"}>
      <Box alignItems="center">
        <Text as="h3" fontSize="3xl">
          {t("titles.ridersWorkScheduleReport")}
        </Text>
      </Box>
      <Box
        className="flex_filters page-container"
        display="flex"
        maxWidth={"380px"}
        flexDirection={"column"}
        alignItems="start"
        justifyContent="start"
        gap={"4"}
        mb="4"
        mt="4"
      >
        <Box>
          <label className={"upload-box-label"}>
            Riders Work Schedule Report:
          </label>

          <Box className="upload-box">
            <BulkUploadBox
              data={{
                bulkUploadApiUrl: API_ROUTES.RIDERS.UPLOAD_RIDER_SHIFT,
                // title: "Orders Report",
                maxValidSize: "200-KB",
                canUpload: true,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RidersWorkScheduleReport;
