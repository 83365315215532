import { API_ROUTES } from 'src/constants/api-routes'
import { AxiosInstance } from '../index'
import { IAxiosResponse } from '../types/axios-response'
import {
  INewZoneData,
  IZonesListPayload,
  IZoneListItem,
  ZoneSorts,
  IZoneAccountData
} from '../types/zones'
import { getListQuerySerialized } from "../../utils/listQuerySerializer";

export const panelZonesService = {
  getAll(
    offset: number,
    count: number,
    sort: {
      colId: string
      sort: string | 'asc' | 'desc'
    }[] = [],
    abortSignal: AbortSignal,
    searchQuery: string = '',
    filter: {
      colId: string
      filter: string
    }[] = []
  ) {
    const keyword = searchQuery ? `&keyword=${searchQuery}` : ''
    let sortKey = sort.length > 0 ? `&` : ''
    sort.forEach(item => {
      if (!item.sort) {
        return
      }
      const sortValue = item.sort === 'asc' ? 'Ascending' : 'Descending'
      sortKey += `&sort_${item.colId}=${sortValue}`
    })
    let filterKey = filter.length > 0 ? `&` : ''
    filter.forEach(item => {
      if (!item.filter) {
        return
      }
      const filterValue = item.filter.toString()
      filterKey += `&filter_${item.colId}=${filterValue}`
    })
    return AxiosInstance.get<IAxiosResponse<IZonesListPayload>>(
      `${API_ROUTES.ZONES.ROOT}?count=${count}&offset=${offset}${sortKey}${filterKey}${keyword}`,
      { signal: abortSignal }
    )
  },
  getTableList({
    offset,
    count,
    sort = [],
    searchQuery = '',
    filter = [],
    abortSignal
  }: {
    offset: number
    count: number
    sort: {
      colId: string
      sort: string | 'asc' | 'desc'
    }[]
    searchQuery: string
    filter: {
      colId: string
      filter: string
    }[]
    abortSignal: AbortSignal
  }) {
    const listAdditionalQuery = getListQuerySerialized({
      sort, filter, searchQuery
    })
    return AxiosInstance.get<IAxiosResponse<IZonesListPayload>>(
      `${API_ROUTES.ZONES.ROOT}?count=${count}&offset=${offset}${listAdditionalQuery}`,
      { signal: abortSignal }
    )
  },
  create(data: INewZoneData, abortSignal: AbortSignal) {
    return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.ZONES.ROOT, data, {
      signal: abortSignal
    })
  },
  get(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IZoneListItem>>(API_ROUTES.ZONES.GET_DETAILS(id), {
      signal: abortSignal
    })
  },
  update(id: string, data: INewZoneData, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.ZONES.UPDATE(id), data, {
      signal: abortSignal
    })
  },
  createAccount(id: string, data: IZoneAccountData, abortSignal: AbortSignal) {
    return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.ZONES.CREATE_ACCOUNT(id), data, {
      signal: abortSignal
    })
  },
  updateAccount(id: string, data: IZoneAccountData, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.ZONES.UPDATE_ACCOUNT(id), data, {
      signal: abortSignal
    })
  },
  disableZoneOTP(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.ZONES.DISABLE_OTP(id), {
      signal: abortSignal
    })
  },
  enableZoneOTP(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(API_ROUTES.ZONES.ENABLE_OTP(id), {
      signal: abortSignal
    })
  },
  removeZoneId(id: string) {
    return AxiosInstance.delete<IAxiosResponse<{}>>(API_ROUTES.ZONES.REMOVE(id))
  }
}
