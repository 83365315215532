import React, { Dispatch, useReducer } from "react";
import { contextFactory } from "src/utils/context-factory";
import { OrdersDataStoreAction } from "src/pages/dashboard/maintenance/tasks/OrdersDataStore/OrdersDataStore-Actions";
import {
  IOrdersDataStoreInitialState,
  ordersDataStoreInitialState,
  ordersDataStoreReducer,
} from "src/pages/dashboard/maintenance/tasks/OrdersDataStore/OrdersDataStore-Reducer";

export const [taskDataStateContext, useTaskDataState] =
  contextFactory<IOrdersDataStoreInitialState>(
    "taskDataStateContext",
    "useTaskDataState"
  );
export const [taskDataDispatchContext, useTaskDataDispatch] = contextFactory<
  Dispatch<OrdersDataStoreAction<any>>
>("taskDataDispatchContext", "useTaskDataDispatch");

const TaskDataProvider: React.FC = ({ children }) => {
  const [taskDataStates, dispatch] = useReducer(
    ordersDataStoreReducer,
    ordersDataStoreInitialState
  );

  return (
    <taskDataStateContext.Provider value={taskDataStates}>
      <taskDataDispatchContext.Provider value={dispatch}>
        {children}
      </taskDataDispatchContext.Provider>
    </taskDataStateContext.Provider>
  );
};

export default TaskDataProvider;
