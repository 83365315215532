import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "src/api/index";
import { IAxiosResponse } from "src/api/types/axios-response";
import {
  INewZoneData,
  IZonesListPayload,
  IZoneListItem,
  ZoneSorts,
  IZoneAccountData,
} from "src/api/types/zones";

export const panelZonesService = {
  getAll(
    offset: number,
    count: number,
    sort: ZoneSorts,
    abortSignal: AbortSignal,
    searchQuery: string = "",
  ) {
    let keyword = searchQuery ? `&keyword=${searchQuery}` : "";
    return AxiosInstance.get<IAxiosResponse<IZonesListPayload>>(
      `${API_ROUTES.MAINTENANCE_DRIVERS.ZONES.ROOT}?count=${count}&offset=${offset}&sort=${sort}${keyword}`,
      { signal: abortSignal },
    );
  },
  create(data: INewZoneData, abortSignal: AbortSignal) {
    return AxiosInstance.post<IAxiosResponse<{}>>(
      API_ROUTES.MAINTENANCE_DRIVERS.ZONES.ROOT,
      data,
      {
        signal: abortSignal,
      },
    );
  },
  get(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IZoneListItem>>(
      API_ROUTES.MAINTENANCE_DRIVERS.ZONES.GET_DETAILS(id),
      { signal: abortSignal },
    );
  },
  update(id: string, data: INewZoneData, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.MAINTENANCE_DRIVERS.ZONES.UPDATE(id),
      data,
      { signal: abortSignal },
    );
  },
  createAccount(id: string, data: IZoneAccountData, abortSignal: AbortSignal) {
    return AxiosInstance.post<IAxiosResponse<{}>>(
      API_ROUTES.MAINTENANCE_DRIVERS.ZONES.CREATE_ACCOUNT(id),
      data,
      { signal: abortSignal },
    );
  },
  updateAccount(id: string, data: IZoneAccountData, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.MAINTENANCE_DRIVERS.ZONES.UPDATE_ACCOUNT(id),
      data,
      { signal: abortSignal },
    );
  },
  disableZoneOTP(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.MAINTENANCE_DRIVERS.ZONES.DISABLE_OTP(id),
      { signal: abortSignal },
    );
  },
  enableZoneOTP(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.MAINTENANCE_DRIVERS.ZONES.ENABLE_OTP(id),
      { signal: abortSignal },
    );
  },
};
