export const getListQuerySerialized = ({
  sort = [],
  filter = [],
  searchQuery = ''
} : {
    sort: {
        colId: string
        sort: string | 'asc' | 'desc'
    }[]
    filter: {
        colId: string
        filter: string
    }[]
    searchQuery: string
}) => {
  const keyword = searchQuery ? `&keyword=${searchQuery}` : ''
  let sortKey = sort.length > 0 ? `&` : ''
  sort.forEach(item => {
    if (!item.sort) {
      return
    }
    const sortValue = item.sort === 'asc' ? 'Ascending' : 'Descending'
    sortKey += `&sort_${item.colId}=${sortValue}`
  })
  let filterKey = filter.length > 0 ? `&` : ''
  filter.forEach(item => {
    if (!item.filter) {
      return
    }
    const filterValue = item.filter.toString()
    filterKey += `&filter_${item.colId}=${filterValue}`
  })
  return `${sortKey}${filterKey}${keyword}`
}
