import React, { useState, useEffect, useCallback, useRef } from "react";
import type { IHeaderParams } from "ag-grid-community";
import './CustomHeaderCheckbox.css'
interface CustomHeaderCheckboxProps extends IHeaderParams {}

const CustomHeaderCheckbox: React.FC<CustomHeaderCheckboxProps> = (props) => {
  const [checked, setChecked] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null); // Optional ref, for possible future uses
  // Define the selector that uniquely identifies your header element
  const headerElSelector = "div.custom-checkbox-header[col-id='checkbox']";

  // Function that applies the desired inline style to the element
  const applyStyle = (el: Element) => {
    if (el.style.left === "15px") {
      return
    }
    (el as HTMLElement).style.left = "15px";
  };

  // Function that sets up a MutationObserver on the header element
  const observeHeader = () => {
    const headerEl = document.querySelector(headerElSelector);
    if (headerEl) {
      // Apply the style immediately
      applyStyle(headerEl);

      // Create the observer to watch for attribute changes (specifically style)
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          // If the style attribute is modified, reapply our style
          if (mutation.attributeName === "style") {
            applyStyle(mutation.target);
          }
        });
      });
      // Start observing attribute changes and filter to only 'style'
      observer.observe(headerEl, { attributes: true, attributeFilter: ["style"] });
    } else {
      // If the header element isn't found (perhaps not rendered yet), retry after a delay
      console.warn("Header element not found, retrying...");
      setTimeout(observeHeader, 100);
    }
  };

  // useEffect to start the observer. Using useEffect instead of DOMContentLoaded event ensures
  // that the code executes after React has rendered the necessary elements.
  useEffect(() => {
    observeHeader();
  }, []);

  // This callback updates internal state (the checkbox) based on AG Grid’s selection.
  const updateCheckboxState = useCallback(() => {
    const displayedNodes = props.api.getRenderedNodes();
    if (displayedNodes.length === 0) return;
    const allSelected = displayedNodes.every((node) => node.isSelected());
    setChecked(allSelected);
  }, [props.api]);

  // Add the event listener for grid selection changes
  useEffect(() => {
    props.api.addEventListener("selectionChanged", updateCheckboxState);
    return () => {
      props.api.removeEventListener("selectionChanged", updateCheckboxState);
    };
  }, [props.api, updateCheckboxState]);

  // Toggle selection when the header checkbox is toggled
  const onCheckboxToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    setChecked(newValue);
    const displayedNodes = props.api.getRenderedNodes();
    displayedNodes.forEach((node) => node.setSelected(newValue));
  };

  return (
    <div
      ref={headerRef}
      style={{ display: "flex", alignItems: "center" }}
      className="custom-checkbox-header"
      data-col-id="checkbox"
    >
      <div className="custom-blue-checkbox">
        <input
          type="checkbox"
          id="header-checkbox"
          checked={checked}
          onChange={onCheckboxToggle}
          className="custom-checkbox-input"
        />
        <label
          htmlFor="header-checkbox"
          className="custom-checkbox-label"
          aria-label={
            checked
              ? "Press Space to toggle row selection (checked)"
              : "Press Space to toggle row selection (unchecked)"
          }
        >
          <div className="checkmark"></div>
        </label>
      </div>
      <span style={{ marginLeft: "10px" }}>{props.displayName}</span>
    </div>
  );
};

export default CustomHeaderCheckbox;
