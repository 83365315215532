import useSignalR from "src/hooks/useSignalR";
import {
  IOngoingOrders,
  IOrderAccepted,
  IOrderChanged,
  OrderFlagType,
} from "src/api/types/orders";
import { useEffect, useState } from "react";
import { HubConnectionState } from "@microsoft/signalr";
import { WebSocketEvents } from "./websocket";

export function useOngoingTasks() {
  const [newTask, setNewTask] = useState<any>({});
  const [acceptedTask, setAcceptedTask] = useState<any>({});
  const [changedTask, setChangedTask] = useState<any>({});
  const [cancelledTask, setCancelledTask] = useState<any>({});
  const [allowToCallAPI, setAlreadyFetchedAPI] = useState<boolean>(false);

  const { connection, connectionStatus } = useSignalR({
    urlPath: "hub/notificationhub",
  });

  const handleMaintenanceRequestCreated = () => {
    connection?.on(
      WebSocketEvents.MaintenanceRequestCreated,
      (order: string) => {
        const orderJSON: IOngoingOrders = JSON.parse(order);
        orderJSON.flag = OrderFlagType.ADDED;

        setNewTask(orderJSON);
      }
    );
  };

  const handleDriverAcceptedEvent = () => {
    connection?.on(
      WebSocketEvents.MaintenanceRequestAcceptedForAdmin,
      (order: string) => {
        const acceptedTask: IOrderAccepted = JSON.parse(order);

        setAcceptedTask(acceptedTask);
      }
    );
  };

  const handleTaskStatusDone = () => {
    connection?.on(
      WebSocketEvents.MaintenanceRequestDoneForAdmin,
      (order: string) => {
        const changedTask: IOrderChanged = JSON.parse(order);
        setChangedTask(changedTask);
      }
    );
  };

  const handleTaskStatusCancelled = () => {
    connection?.on(
      WebSocketEvents.MaintenanceRequestCancelledForAdmin,
      (order: string) => {
        const cancelledTask: IOrderChanged = JSON.parse(order);
        setCancelledTask(cancelledTask);
      }
    );
  };

  useEffect(() => {
    if (connectionStatus === HubConnectionState.Connected) {
      setAlreadyFetchedAPI(true);
      return () => {
        setAlreadyFetchedAPI(false);
      };
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (connection) {
      handleMaintenanceRequestCreated();
      handleDriverAcceptedEvent();
      handleTaskStatusDone();
      handleTaskStatusCancelled();
    }
  }, [connection]);

  return { newTask, acceptedTask, changedTask, allowToCallAPI, cancelledTask };
}
