import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Select,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import "./ChangeWorkingStatusModal.css";
import { workingStatusService } from "src/api/services/working-status";

interface ChangeWorkingStatusModalProps {
    isOpen: boolean;
    closeModal: () => void;
    data: {
        title: string;
        selectedRiders: string[];
        onSave: (currentShiftStatus: string, nextShiftStatus: string) => Promise<void>;
        onCancel: () => void;
    };
}

const ChangeWorkingStatusModal: React.FC<ChangeWorkingStatusModalProps> = ({
  data,
  closeModal,
}) => {
  const [workingStatuses, setWorkingStatuses] = useState([]);
  const [currentShiftStatus, setCurrentShiftStatus] = useState("");
  const [nextShiftStatus, setNextShiftStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(true);

  useEffect(() => {
    const fetchWorkingStatuses = async () => {
      try {
        const response = await workingStatusService.getAll();
        setWorkingStatuses(response.data.data.items || []);
      } catch (error) {
        console.error("Error fetching working statuses:", error);
      }
    };

    fetchWorkingStatuses();
  }, []);

  const handleSave = async () => {
    if (currentShiftStatus || nextShiftStatus) {
      setIsLoading(true);
      await data.onSave(currentShiftStatus, nextShiftStatus);
      setIsLoading(false)
    }
  };

  const selectedRidersCount = data.selectedRiders?.length || 0;

  return (
    <Modal isOpen={modal} onClose={() => { setModal(false) }} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="16px" p={2}>
        <ModalHeader fontSize="xl" fontWeight="500">
          {data.title}
        </ModalHeader>
        <ModalBody>
          <Text mb={6}>
            Please select the new working status for the {selectedRidersCount} selected riders.
          </Text>

          <Box mb={4}>
            <Text fontWeight="medium" mb={2}>
              Working status (current Shift)
            </Text>
            <Select
              placeholder="Select working status"
              value={currentShiftStatus}
              onChange={(e) => setCurrentShiftStatus(e.target.value)}
              size="lg"
            >
              {workingStatuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.title}
                </option>
              ))}
            </Select>
          </Box>

          <Box mb={4}>
            <Text fontWeight="medium" mb={2}>
              Working status (next Shift)
            </Text>
            <Select
              placeholder="Select working status"
              value={nextShiftStatus}
              onChange={(e) => setNextShiftStatus(e.target.value)}
              size="lg"
            >
              {workingStatuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.title}
                </option>
              ))}
            </Select>
          </Box>
        </ModalBody>

        <ModalFooter display="flex" gap={3}>
          <Button
            size="lg"
            w="100%"
            style={{
              border: "1px solid #B3B3B3",
              borderRadius: "8px",
              backgroundColor: "#FEFEFE",
              color: "#808080",
            }}
            onClick={() => { setModal(false) }}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            w="100%"
            isDisabled={!currentShiftStatus || !nextShiftStatus}
            isLoading={isLoading}
            style={{
              borderRadius: "8px",
              backgroundColor: "#FEE4BA",
              color: "#000000",
              boxShadow: "0px 24px 32px 0px #52525214",
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangeWorkingStatusModal;
