import React, { memo, useEffect, useRef, useState, useCallback } from "react";
import { ILatLng } from "src/types/map";
import {
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  MAP_API_KEY,
} from "src/constants/map-data";
import {
  GoogleMap,
  LoadScript,
  DrawingManager,
  Polygon,
  Libraries,
  Marker,
} from "@react-google-maps/api";
import { ReactComponent as SelectAreaIcon } from "src/assets/icons/map/select-area.svg";
import { ReactComponent as MarkerIcon } from "src/assets/icons/map/Marker.svg";
import { ReactComponent as ResetIcon } from "src/assets/icons/map/ResetArrow.svg";

const GOOGLE_MAPS_LIBRARIES: Libraries = ["drawing"];

interface IPolygonMapProps {
  width?: number | string;
  height?: number | string;
  zoom?: number;
  center?: ILatLng;
  onChange?: ({
    coordinates,
    marker,
  }: {
    coordinates?: ILatLng[];
    marker?: ILatLng | null;
  }) => void;
  onPolygonChanged?: (coordinates: ILatLng[] | null) => void;
  onMarkerChanged?: (marker: ILatLng | null) => void;
  defaultPolyline?: ILatLng[];
  defaultMarker?: ILatLng;
  defaultMode?: "polygon" | "marker" | "none";
  editable?: boolean;
  fullscreenControl?: boolean;
}

export const PolygonMap = memo<IPolygonMapProps>(
  ({
    width = "100%",
    height = "500px",
    zoom = DEFAULT_ZOOM,
    center: propCenter = DEFAULT_CENTER,
    onPolygonChanged,
    onMarkerChanged,
    defaultPolyline = [],
    defaultMarker = DEFAULT_CENTER,
    defaultMode = "none",
    editable = true,
    fullscreenControl = true,
  }) => {
    const [path, setPath] = useState<ILatLng[]>([]);
    const [isDrawing, setIsDrawing] = useState(false);
    const mapRef = useRef<google.maps.Map | null>(null);
    const polylineRef = useRef<google.maps.Polyline | null>(null);
    const [mode, setMode] = useState<"polygon" | "marker" | "none">(
      defaultMode
    );
    const [markerPosition, setMarkerPosition] = useState<ILatLng | null>(
      defaultMarker
    );
    const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);

    const basePolygonStyle = {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
    };

    const editablePolygonOptions = {
      ...basePolygonStyle,
      editable: true,
      draggable: false,
    };

    const displayPolygonOptions = {
      ...basePolygonStyle,
      editable: false,
      draggable: false,
    };

    const mapContainerStyle = {
      width,
      height,
    };

    const polygonOptions = {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      editable,
      draggable: false,
    };

    const drawingManagerOptions = {
      drawingControl: false,
      polygonOptions: polygonOptions,
      drawingMode: null,
    };

    const handleMapClick = useCallback(
      (e: google.maps.MapMouseEvent) => {
        if (mode === "marker" && editable) {
          const newPosition = {
            lat: e.latLng!.lat(),
            lng: e.latLng!.lng(),
          };
          setMarkerPosition(newPosition);
          onMarkerChanged?.(newPosition);
        }
      },
      [mode, editable]
    );

    const toggleMode = useCallback(() => {
      setMode((prev) => (prev === "polygon" ? "marker" : "polygon"));
      setIsDrawing(false);
    }, []);

    const onPolygonComplete = useCallback(
      (polygon: google.maps.Polygon) => {
        if (polylineRef.current) {
          polylineRef.current.setMap(null);
        }

        const points = polygon
          .getPath()
          .getArray()
          .map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
          }));

        const listeners: google.maps.MapsEventListener[] = [];

        if (editable) {
          listeners.push(
            google.maps.event.addListener(polygon.getPath(), "set_at", () => {
              const newPoints = polygon
                .getPath()
                .getArray()
                .map((latLng) => ({
                  lat: latLng.lat(),
                  lng: latLng.lng(),
                }));
              onPolygonChanged?.(newPoints);
            }),
            google.maps.event.addListener(
              polygon.getPath(),
              "insert_at",
              () => {
                const newPoints = polygon
                  .getPath()
                  .getArray()
                  .map((latLng) => ({
                    lat: latLng.lat(),
                    lng: latLng.lng(),
                  }));
                setPath(newPoints);
                onPolygonChanged?.(newPoints);
              }
            )
          );
        }

        polygon.setMap(null);
        setPath(points);
        onPolygonChanged?.(points);
        setIsDrawing(false);

        return () => {
          listeners.forEach((listener) => listener.remove());
        };
      },
      [editable, onPolygonChanged]
    );

    const resetMap = useCallback(() => {
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
      }
      setMarkerPosition(null);
      onMarkerChanged?.(null);
      setPath([]);
      setIsDrawing(false);
      if (mapRef.current) {
        mapRef.current.panTo(propCenter);
        mapRef.current.setZoom(zoom);
      }
      onPolygonChanged?.([]);
      setIsDrawing(false);
      setMode("none");
    }, [propCenter, zoom, onMarkerChanged, onPolygonChanged]);

    const toggleDrawing = useCallback(() => {
      setIsDrawing((prev) => !prev);
    }, []);

    useEffect(() => {
      if (defaultPolyline.length >= 2 && !isDrawing) {
        setPath(defaultPolyline);
        onPolygonChanged?.(defaultPolyline);
      }
    }, [defaultPolyline]);

    useEffect(() => {
      if (polygon && editable) {
        const polygonPath = polygon.getPath();

        google.maps.event.addListener(polygonPath, "set_at", () => {
          const coordinates = Array.from(polygonPath.getArray()).map(
            (latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            })
          );
          onPolygonChanged?.(coordinates);
        });

        google.maps.event.addListener(polygonPath, "insert_at", () => {
          const coordinates = Array.from(polygonPath.getArray()).map(
            (latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            })
          );
          onPolygonChanged?.(coordinates);
        });

        return () => {
          google.maps.event.clearInstanceListeners(polygonPath);
        };
      }
    }, [polygon, editable]);

    useEffect(() => {
      setMarkerPosition(defaultMarker);
      onMarkerChanged?.(defaultMarker);
    }, [defaultMarker]);

    useEffect(() => {
      if (mapRef.current) {
        mapRef.current.panTo(propCenter);
      }
    }, [propCenter]);
    // @ts-ignore
    return (
      <div style={{ position: "relative" }}>
        {/*// @ts-ignore */}
        <LoadScript
          googleMapsApiKey={MAP_API_KEY}
          libraries={GOOGLE_MAPS_LIBRARIES}
        >
          {/*// @ts-ignore */}
          <GoogleMap
            options={{
              streetViewControl: false,
              fullscreenControl,
              gestureHandling: "greedy",
            }}
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            onLoad={(map) => {
              mapRef.current = map;
              map.setCenter(propCenter);
            }}
            onClick={handleMapClick}
          >
            {mode === "polygon" && editable && (
              <DrawingManager
                options={{
                  ...drawingManagerOptions,
                  drawingMode: isDrawing
                    ? google?.maps?.drawing?.OverlayType?.POLYGON
                    : null,
                }}
                onPolygonComplete={onPolygonComplete}
              />
            )}

            {path.length >= 2 && !isDrawing && (
              <Polygon
                path={path}
                options={
                  editable ? editablePolygonOptions : displayPolygonOptions
                }
                onLoad={(polygonInstance) => {
                  setPolygon(polygonInstance);
                }}
                onUnmount={() => {
                  setPolygon(null);
                }}
              />
            )}

            {markerPosition && (
              <>
                {/*// @ts-ignore */}
                <Marker
                  position={markerPosition}
                  draggable={editable && mode === "marker"}
                  onDragEnd={(e: google.maps.MapMouseEvent) => {
                    if (mode === "marker") {
                      setMarkerPosition({
                        lat: e.latLng!.lat(),
                        lng: e.latLng!.lng(),
                      });
                      onMarkerChanged?.({
                        lat: e.latLng!.lat(),
                        lng: e.latLng!.lng(),
                      });
                    }
                  }}
                />
              </>
            )}
          </GoogleMap>
        </LoadScript>

        {editable && (
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              left: "16px",
              display: "flex",
              gap: "10px",
              zIndex: 10,
            }}
          >
            <button
              onClick={resetMap}
              style={{
                padding: "10px",
                backgroundColor: "#fff",
                border: "1px solid rgba(204, 204, 204, 1)",
                borderRadius: "4px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "4px",
                boxShadow: "0px 2px 1px -1px #00000033",
              }}
            >
              <ResetIcon />
              Reset Map
            </button>
            {mode === "none" && (
              <button
                onClick={() => {
                  setMode("marker");
                  setIsDrawing(false);
                }}
                style={{
                  zIndex: 1000,
                  padding: "10px",
                  backgroundColor: "#fff",
                  border: "1px solid rgba(204, 204, 204, 1)",
                  borderRadius: "4px",
                  cursor: "pointer",
                  color: "#95161B",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "0px 2px 1px -1px #00000033",
                  gap: "4px",
                }}
              >
                <MarkerIcon />
                Select Zone Location
              </button>
            )}
            {mode === "marker" && markerPosition && !isDrawing && (
              <button
                onClick={() => {
                  setMode("polygon");
                  setIsDrawing(true);
                }}
                style={{
                  zIndex: 1000,
                  padding: "10px",
                  backgroundColor: "#fff",
                  border: "1px solid rgba(204, 204, 204, 1)",
                  borderRadius: "4px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "4px",
                  color: "#95161B",
                  boxShadow: "0px 2px 1px -1px #00000033",
                }}
              >
                <SelectAreaIcon />
                Select Area
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default PolygonMap;
