import { ModalsList } from "src/components/app/Modal/modalsList";

export enum MODAL_TYPES {
  CONFIRMATION_MODAL = "confirmationModal",
  TARGET_GROUP_RIDERS = "targetGroupRiders",
  BULK_UPLOAD_MODAL = "bulkUploadModal",
  MANAGE_ZONE_RIDERS_MODAL = "manageZoneRidersModal",
  MANAGE_M_ZONE_DRIVERS_MODAL = "manageMZoneDriversModal",
  MANAGE_M_ZONES_MODAL = "manageAssigneeModal",
  EDIT_ZONE_LOCATION_MODAL = "editZoneLocationModal",
  GROUP_ZONES_MODAL = "groupZoneModal",
  ADD_NEW_MESSAGE_MODAL = "addNewMessageModal",
  CHANGE_TARGET_GROUP_MODAL = 'changeTargetGroupModal',
  CHANGE_WORKING_STATUS_MODAL = 'changeWorkingStatusModal'
}

export interface Modal {
  type: MODAL_TYPES;
  data: any;
}

export type ModalState = Modal[];

export interface ModalProps<D = any> {
  closeModal: (clearModals?: boolean) => void;
  data: D;
}

// this type throws error if ModalList is empty

// @ts-ignore
export type ModalData<T extends ModalTypes> = Parameters<
  // @ts-ignore
  typeof ModalsList[T]
  // @ts-ignore
>["0"]["data"];

export type ModalList = {
  [key in MODAL_TYPES]: React.FC<ModalProps>;
};
