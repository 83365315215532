import React from 'react'

const NoImageSvg = () => {
  return (
    <svg
      width='223'
      height='215'
      viewBox='0 0 223 215'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M150.232 84.5425C134.406 84.5425 118.295 83.9029 103.225 79.7263C88.4384 75.6438 74.8619 67.7234 62.7603 58.5614C54.8376 52.5975 47.6334 47.8566 37.347 48.5715C27.2819 49.1041 17.6559 52.844 9.89155 59.2386C-3.19329 70.6395 -1.22678 91.748 4.01093 106.554C11.877 128.885 35.8154 144.425 56.1612 154.509C79.6647 166.192 105.494 172.965 131.38 176.878C154.071 180.302 183.228 182.804 202.893 168.055C220.951 154.509 225.905 123.58 221.48 102.697C220.408 96.5226 217.106 90.9499 212.196 87.0259C199.508 77.8074 180.581 83.9593 166.323 84.2791C161.029 84.392 155.64 84.5049 150.232 84.5425Z'
        fill='#E6E6E6'
      />
      <path
        d='M111.5 214.5C149.884 214.5 181 212.485 181 210C181 207.515 149.884 205.5 111.5 205.5C73.1162 205.5 42 207.515 42 210C42 212.485 73.1162 214.5 111.5 214.5Z'
        fill='#E6E6E6'
      />
      <path
        d='M199.5 23.5C199.5 24.3284 198.828 25 198 25C197.172 25 196.5 24.3284 196.5 23.5C196.5 22.6716 197.172 22 198 22C198.828 22 199.5 22.6716 199.5 23.5Z'
        fill='#FAA51A'
        stroke='#FBAE31'
      />
      <path
        d='M76.5 7.5C76.5 8.32843 75.8284 9 75 9C74.1716 9 73.5 8.32843 73.5 7.5C73.5 6.67158 74.1716 6 75 6C75.8284 6 76.5 6.67157 76.5 7.5Z'
        fill='#F39905'
        stroke='#FBAE31'
      />
      <path
        d='M98.5 184.5C98.5 184.955 98.3612 185.347 98.1607 185.614C97.9603 185.881 97.7216 186 97.5 186C97.2785 186 97.0398 185.881 96.8393 185.614C96.6388 185.347 96.5 184.955 96.5 184.5C96.5 184.045 96.6388 183.653 96.8393 183.386C97.0398 183.119 97.2785 183 97.5 183C97.7216 183 97.9603 183.119 98.1607 183.386C98.3612 183.653 98.5 184.045 98.5 184.5Z'
        fill='#CFCFCF'
        stroke='#FBAE31'
      />
      <path d='M147 0.5V9.5' stroke='#FBAE31' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M143 4.5H151' stroke='#FBAE31' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M15 34.5V43.5' stroke='#FBAE31' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M10 39.5H18' stroke='#FBAE31' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M20 148.5V156.5' stroke='#FBAE31' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M16 152.5H24' stroke='#FBAE31' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M189 64.5V72.5' stroke='#FBAE31' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M184 69.5H192' stroke='#FBAE31' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M131.723 17.5976L36.814 30.0604C30.6625 30.8681 26.3306 36.5097 27.1384 42.6611L41.6045 152.826C42.4123 158.978 48.0538 163.31 54.2053 162.502L149.114 150.039C155.265 149.231 159.597 143.59 158.789 137.438L144.323 27.2732C143.516 21.1218 137.874 16.7899 131.723 17.5976Z'
        fill='#FBAE31'
      />
      <path
        d='M158.784 33.5H63.2157C57.0214 33.5 52 38.5454 52 44.7693V156.231C52 162.455 57.0214 167.5 63.2157 167.5H158.784C164.979 167.5 170 162.455 170 156.231V44.7693C170 38.5454 164.979 33.5 158.784 33.5Z'
        fill='white'
        stroke='#BABABA'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M67 121.801L86.0112 90.5742C86.1982 90.118 86.5154 89.728 86.9226 89.4536C87.3298 89.1792 87.8086 89.0327 88.2985 89.0327C88.7884 89.0327 89.2672 89.1792 89.6744 89.4536C90.0816 89.728 90.3988 90.118 90.5858 90.5742L101.366 108.275C101.549 108.733 101.864 109.125 102.27 109.401C102.676 109.677 103.155 109.825 103.644 109.825C104.134 109.825 104.613 109.677 105.019 109.401C105.424 109.125 105.739 108.733 105.922 108.275L124.371 78.0495C124.554 77.5918 124.869 77.1997 125.275 76.9236C125.681 76.6475 126.159 76.5 126.649 76.5C127.139 76.5 127.617 76.6475 128.023 76.9236C128.429 77.1997 128.744 77.5918 128.927 78.0495L156 122.5L67 121.801Z'
        fill='white'
        stroke='#BABABA'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M105 86.5C108.314 86.5 111 83.8137 111 80.5C111 77.1863 108.314 74.5 105 74.5C101.686 74.5 99 77.1863 99 80.5C99 83.8137 101.686 86.5 105 86.5Z'
        fill='white'
        stroke='#BABABA'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default NoImageSvg
