import React, { useCallback, useMemo, useState } from 'react'
import { Box, Text, IconButton, HStack, VStack } from '@chakra-ui/react'
import Icon from '@mdi/react'
import { mdiEyeOutline, mdiStar } from '@mdi/js'
import { useTranslation } from 'react-i18next'
import { useTitle } from 'src/hooks/useTitle'
import { Link } from 'react-router-dom'
import ROUTE_CONSTANTS from 'src/Routes/route-constants'
import { SearchInput } from 'src/components/app/SearchInput'
import { useAbortController } from 'src/hooks/useAbortController'
import TableAGGrid from 'src/components/app/Table/TableAGGrid'
import { toast } from 'src/utils/toast'
import { panelFeedbackService } from 'src/api/services/feedback'
import dayjs from 'dayjs'

// Utility to check if a string is valid JSON
const isValidJSON = (str: any) => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

const useQueryParams = () => {
  const { search } = window.location
  const [paramsState, setParamsState] = useState(() => {
    const params = new URLSearchParams(search)
    const sort = params.get('sort')
    const filter = params.get('filter')
    return {
      size: Number(params.get('size')) || 10,
      offset: Number(params.get('offset')) || 0,
      query: params.get('query') || '',
      sort: sort && isValidJSON(sort) ? JSON.parse(sort) : [],
      filter: filter && isValidJSON(filter) ? JSON.parse(filter) : []
    }
  })
  return [paramsState, setParamsState] as const
}

const Feedback = () => {
  const { t } = useTranslation()
  useTitle(t('pageTitles.restaurantFeedback'))
  const { getAbortSignal } = useAbortController()

  const [paramsState, setParamsState] = useQueryParams()
  // Destructure sort and filter from params; the rest is our pagination and query data.
  const { sort: sortModel, filter: filterModel, ...pagination } = paramsState

  const [searchTerm, setSearchTerm] = useState(pagination.query)

  // Callback for handling sort changes from AG Grid
  const onSortChanged = useCallback(
    ({ sortModel: newSortModel }: any) => {
      setParamsState(prev => ({
        ...prev,
        sort: newSortModel,
        offset: 0
      }))
    },
    [setParamsState]
  )

  // Callback for handling filter changes from AG Grid
  const onFilterChanged = useCallback(
    ({ filterModel: newFilterModel }: any) => {
      setParamsState(prev => ({
        ...prev,
        filter: newFilterModel,
        offset: 0
      }))
    },
    [setParamsState]
  )

  // Callback for pagination changes
  const handlePageChange = useCallback(
    (offset: number, size: number) => {
      setParamsState(prev => ({
        ...prev,
        offset,
        size
      }))
    },
    [setParamsState]
  )

  // onFetchData function for TableAGGrid's infinite scroll datasource
  const handleFetchData = useCallback(
    async ({
      sortModel,
      filterModel,
      paginationModel
    }: {
      sortModel: any[]
      filterModel: any[]
      paginationModel: any
    }) => {
      try {
        const response = await panelFeedbackService.getAll(
          paginationModel.offset,
          paginationModel.size,
          sortModel,
          getAbortSignal('fetchFeedbacks').signal,
          paginationModel.query || searchTerm,
          filterModel
        )
        return {
          items: response.data.data.items || [],
          totalCount: response.data.data.totalCount
        }
      } catch (e) {
        toast.error('Failed to fetch feedbacks')
        throw e
      }
    },
    [getAbortSignal, searchTerm]
  )

  const handleSearch = useCallback(
    (query: string) => {
      setSearchTerm(query)
      setParamsState(prev => ({
        ...prev,
        query,
        offset: 0
      }))
    },
    [setParamsState]
  )

  // Define your columns as needed; using useMemo for performance
  const columnsArray = useMemo(() => {
    return [
      {
        headerName: 'Created At',
        field: 'createdAt',
        sortable: false,
        filter: false,
        resizable: false,
        cellRenderer: (params: any) => {
          return <Box>{dayjs(params?.data?.createdAt).format('DD-MMM-YYYY')}</Box>
        }
      },
      {
        headerName: 'Rider Employee ID',
        field: 'rider.employeeId',
        sortable: false,
        filter: false,
        resizable: false
      },
      {
        headerName: 'Rider Name',
        field: 'rider.firstName',
        sortable: false,
        filter: false,
        resizable: false,
        cellRenderer: (params: any) => {
          return (
            <Box isTruncated maxW='fit-content'>
              {params?.data?.rider?.firstName + ' ' + params?.data?.rider?.lastName}
            </Box>
          )
        }
      },
      {
        headerName: 'Contact Number',
        field: 'rider.contactNo',
        sortable: false,
        resizable: false,
        filter: false
      },

      {
        headerName: 'Zone Name',
        field: 'zoneName',
        sortable: false,
        filter: false,
        resizable: false,
        cellRenderer: (params: any) => {
          return <Box isTruncated>{params?.data?.zoneName}</Box>
        }
      },
      {
        headerName: 'Rate',
        field: 'score',
        sortable: false,
        resizable: false,
        filter: false,
        cellRenderer: (params: any) => {
          return (
            <Box>
              <HStack spacing={1}>
                {[1, 2, 3, 4, 5].map(star => (
                  <VStack key={star} align='center'>
                    <Icon
                      path={mdiStar}
                      color={star <= (params?.data?.score ?? 0) ? '#F2C94C' : '#E0E0E0'}
                      size={'20px'}
                    />
                  </VStack>
                ))}
              </HStack>
            </Box>
          )
        }
      },
      {
        headerName: '',
        field: 'actions',
        sortable: false,
        filter: false,
        cellRenderer: (params: any) => {
          if (!params.data) {
            return <></>
          }
          return (
            <Box display='flex' gap={2}>
              <IconButton
                aria-label='Test'
                icon={<Icon path={mdiEyeOutline} size='28px' />}
                color='gray.100'
                bg='blue.300'
                as={Link}
                to={
                  ROUTE_CONSTANTS.DASHBOARD.RESTAURANT_FEEDBACK.DETAILS.BY_DATA(params.data.id)
                    .ABSOLUTE
                }
              />
            </Box>
          )
        }
      }
    ]
  }, [])

  return (
    <Box>
      <Box display='flex' alignItems='center'>
        <Text as='h3' fontSize='3xl'>
          {t('titles.restaurantFeedback')}
        </Text>
      </Box>
      <Box mb='4' mt='4' display='flex' justifyContent='space-between' alignItems='center'>
        <SearchInput
          searchPlaceholder={t('actions.search')}
          value={searchTerm}
          onSearchInput={handleSearch}
        />
      </Box>
      <Box p='4' background='white' borderRadius='16px' border='1px solid #E6E6E6'>
        <TableAGGrid
          key={searchTerm}
          loading={false}
          onFetchData={handleFetchData}
          columns={columnsArray as any}
          isSelectable={false}
          paginationConfig={{
            passPaginateValues: handlePageChange,
            entityTitle: 'Feedback'
          }}
          onSortChanged={onSortChanged}
          onFilterChanged={onFilterChanged}
        />
      </Box>
    </Box>
  )
}

export default Feedback
