import React from "react";
import { getAuthorization } from "./get-authorization";
import { getListQuerySerialized } from "./listQuerySerializer";
import { IRiderListItem } from "../api/types/riders";
import { ApiConfig } from "../api/config";
import { API_ROUTES } from "../constants/api-routes";

export const riderListExportFile = ({
  offset,
  count,
  sort = [],
  searchQuery = '',
  filter = [],
  status = '',
} : {
    offset: number
    count: number
    sort: {
        colId: string
        sort: string | 'asc' | 'desc'
    }[]
    searchQuery: string
    filter: {
        colId: string
        filter: string
    }[]
    status: string
}, exportButtonRef: React.RefObject<HTMLButtonElement>, fileName: string) => {
  const buttonIcon = `<svg viewBox="0 0 24 24" role="presentation" style="width: 25px; height: 25px;"><path d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z" style="fill: rgb(51, 51, 51);"></path></svg>`;
  exportButtonRef.current!.innerHTML = `${buttonIcon}&nbsp;&nbsp;Exporting...`;

  const listAdditionalQuery = getListQuerySerialized({
    sort, filter, searchQuery
  })
  let searchedStatus = status ? `&filter_workingStatus=${status}` : "";
  fetch(`${ApiConfig.baseURL + API_ROUTES.RIDERS.EXPORT_V2}?count=${count}&offset=${offset}${listAdditionalQuery}${searchedStatus}`, {
    method: "GET",
    headers: new Headers({
      "Authorization": "" + getAuthorization(),
      "Content-Type": "data:text/csv;charset=utf-8"
    })
  })
    .then(response => response.blob())
    .then(blob => {
      let b = new Blob(["\ufeff", blob], { type: "text/csv;charset=ISO-8859-1" });
      const createdUrl = URL.createObjectURL(b);
      let a = document.createElement("a");
      a.href = createdUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      exportButtonRef.current!.innerHTML = `${buttonIcon}&nbsp;&nbsp;Export`;
    });
}
