import { ChangeEvent, memo, useEffect, useState } from "react";
import { calcAndShowFileSize } from "../../../utils/file-size-helper";
import { ApiConfigForUpload } from "src/api/config";
import { getAuthorization } from "../../../utils/get-authorization";
import { Box, Text } from "@chakra-ui/react";
import { ShowSuccessStateNoModal } from "./components/ShowSuccessStateNoModal";
import { BulkFileUploaderNoModal } from "./components/BulkFileUploaderNoModal";
import { FileUploaderActionsNoModal } from "./components/FileUploaderActionsNoModal";
import axios from "axios";

interface BulkUploadType {
  data: {
    title?: string;
    maxValidSize: string;
    bulkUploadApiUrl: string;
    canUpload?: boolean;
    dataToAttach?: [
      {
        key: string;
        value: string;
      }
    ];
    onFileUploaded?: (response: any) => void;
  };
}

export const BulkUploadBox = memo(({ data }: BulkUploadType) => {
  const {
    title,
    maxValidSize,
    bulkUploadApiUrl,
    canUpload = false,
    dataToAttach,
    onFileUploaded,
  } = data;
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const [wasSelectedFile, setWasSelectedFile] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<null | File>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [exceptionMessage, setExceptionMessage] = useState<string[]>([]);
  const [doneImportItems, setDoneImportItems] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const helpMessages = [
    "Import file must be .xlsx format",
    "Excel file must follow the specific structure of the shared template",
    "Import file should not exceed " +
      maxValidSize?.split("-")[0] +
      maxValidSize?.split("-")[1] +
      " in size",
  ];

  const axiosInstance = axios.create(ApiConfigForUpload);


  const handleCloseModal = (isAfterUpload = false) => {
    setSelectedFile(null);
    setWasSelectedFile(false);
    setIsUploading(false);
    setExceptionMessage([]);
    onFileUploaded && onFileUploaded(data);

    if (isAfterUpload) {
      setDoneImportItems(false);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 200);
    }
  };

  const handleChooseFile = (e: ChangeEvent<HTMLInputElement>) => {
    setExceptionMessage([]);
    if (!e.target.files || e.target.files.length === 0) {
      // file not selected
      setExceptionMessage(["File not selected yet!"]);
      return;
    } else {
      let choosenFile = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(choosenFile);

      let validateFileResult = validateFile(choosenFile);
      if (!validateFileResult[0]) {
        setExceptionMessage([validateFileResult[1]]);
        return;
      }
      setWasSelectedFile(true);
      setSelectedFile(choosenFile);
    }
  };

  const validateFile = (choosenFile: File): [boolean, string] => {
    var validExt = ["xlsx"];
    let fileName = choosenFile.name;
    let fileExt = fileName.split(".").pop();
    if (validExt.indexOf(fileExt?.toLocaleLowerCase()!) === -1) {
      return [false, "File extension is Invalid!"];
    }

    if (choosenFile.size >= calcAndShowFileSize(maxValidSize).fileSize) {
      return [
        false,
        "File size is greater than " +
          calcAndShowFileSize(maxValidSize).viewForm,
      ];
    }

    return [true, ""];
  };

  const removeSelectedFile = () => {
    setSelectedFile(null);
    setWasSelectedFile(false);
  };

  const onUploadFile = () => {
    setIsUploading(true);
    setExceptionMessage([]);

    const formData = new FormData();
    formData.append("File", selectedFile as Blob);
    dataToAttach?.forEach((item) => {
      formData.append(item.key, item.value);
    });

    // if admin select avatar
    axiosInstance.post(
      bulkUploadApiUrl,
       formData,
      {
        headers: {
          Authorization: getAuthorization()!,
          timeout: 30 * 1000,
          accept: "application/json",
        }
    })
      .then((response) => {
        const { messageType } = response.data.meta || {};
        if (messageType === "Success") {
          setDoneImportItems(true);
          setSuccessMessage("File uploaded successfully");
        }
      })
      .catch((error) => {
        console.log(error);
        removeSelectedFile()
        const errorTxt = error.response ? error.response.data.meta.message :
            "An error occurred. Please try again."
        setExceptionMessage([errorTxt]);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  useEffect(() => {
    setDisabledSubmit(!canUpload || !selectedFile);
  }, [canUpload, selectedFile]);

  return (
    <>
      <Box mb="3" mt="3">
        <Text as="h3" textAlign="center">
          <Text as="span" fontWeight="500" fontSize="1.2rem">
            {title ? `${title} Upload` : ""}
          </Text>
        </Text>
      </Box>
      {doneImportItems ? (
        <ShowSuccessStateNoModal
          selectedFile={selectedFile}
          handleClose={handleCloseModal}
          message={successMessage}
        />
      ) : (
        <>
          <BulkFileUploaderNoModal
            exceptionMessage={exceptionMessage}
            handleChooseFile={handleChooseFile}
            isUploading={isUploading}
            removeSelectedFile={removeSelectedFile}
            selectedFile={selectedFile}
            wasSelectedFile={wasSelectedFile}
            helpMessages={helpMessages}
            maxValidSize={maxValidSize}
          />

          <FileUploaderActionsNoModal
            disabledSubmit={disabledSubmit}
            handleCloseModal={handleCloseModal}
            isUploading={isUploading}
            handleUploadFile={onUploadFile}
          />
        </>
      )}
    </>
  );
});
