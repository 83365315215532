import React, { useEffect, useState } from "react";
import useSupercluster from "use-supercluster";
import { GoogleMap, useJsApiLoader, Circle, Marker } from '@react-google-maps/api';
import { useExtendedLiveMapState } from "../../ExtendedLiveMapContext";
import { DEFAULT_CENTER, MAP_API_KEY, DEFAULT_ZOOM } from "src/constants/map-data";
import { ILocationLog } from "src/api/types/rider-locations";

const containerStyle = {
    width: '100%',
    height: '100%'
};

const LiveMap = () => {
    const maxZoomClustering = 16;
    const { locationLogs, mapCenter, activeZone, activeLocationLog } = useExtendedLiveMapState();

    const [zoom, setZoom] = useState(DEFAULT_ZOOM);
    const [riders, setRiders] = useState(locationLogs);
    const [bounds, setBounds] = useState(null);
    const [map, setMap] = useState(null);
    const [googleMaps, setGoogleMaps] = useState(null);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: MAP_API_KEY
    });

    useEffect(() => {
        const filteredItems = locationLogs.filter(lg => lg.status !== "None");
        setRiders(filteredItems);
    }, [locationLogs]);

    useEffect(() => {
        (activeLocationLog?.rider?.id && isAllZoneSelected() && zoom < maxZoomClustering) && setZoom(15);
    }, [activeLocationLog, zoom]);

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
        setGoogleMaps(window.google.maps);
    }, []);

    const onUnmount = React.useCallback(function callback() {
        setMap(null);
    }, []);

    const makePoints = (locationsList) => {
        return locationsList.filter(l => l.location !== null).map(crime => ({
            type: "Feature",
            properties: {
                cluster: false,
                crimeId: crime.rider.id,
                category: crime.rider?.zone?.id || ""
            },
            geometry: {
                type: "Point",
                coordinates: [
                    parseFloat(crime.location.longitude.toString()),
                    parseFloat(crime.location.latitude.toString())
                ]
            }
        }));
    }

    const { clusters } = useSupercluster({
        points: makePoints(locationLogs),
        bounds,
        zoom,
        options: { radius: 50, maxZoom: maxZoomClustering }
    });

    const isAllZoneSelected = () => {
        return activeZone && activeZone.id && activeZone?.id.trim() === "";
    }

    const onBoundsChanged = () => {
        if (map) {
            const bounds = map.getBounds();
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();

            setBounds([
                sw.lng(),
                sw.lat(),
                ne.lng(),
                ne.lat()
            ]);
        }
    };

    const onZoomChanged = () => {
        if (map) {
            setZoom(map.getZoom());
        }
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter || DEFAULT_CENTER}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onBoundsChanged={onBoundsChanged}
            onZoomChanged={onZoomChanged}
            options={{ mapTypeControl: true }}
        >
            {/* Circle for active zone */}
            {(activeZone && activeZone.location && activeZone?.id?.trim() !== "") && (
                <>
                    <Marker
                        position={{
                            lat: activeZone.location.latitude,
                            lng: activeZone.location.longitude
                        }}
                    />
                    <Circle
                        center={{
                            lat: activeZone.location.latitude,
                            lng: activeZone.location.longitude
                        }}
                        options={{
                            strokeColor: "#FF0000",
                            strokeOpacity: 0.8,
                            strokeWeight: 1,
                            fillColor: "#FF0000",
                            fillOpacity: 0.35,
                            radius: activeZone.validRadius
                        }}
                    />
                </>
            )}

            {/* Render clusters or individual markers based on zoom level */}
            {zoom < maxZoomClustering - 1 && isAllZoneSelected()
                ? clusters.map((cluster, i) => {
                    const [lng, lat] = cluster.geometry.coordinates;
                    const { cluster: isCluster, point_count: pointCount } = cluster.properties;

                    if (isCluster) {
                        return (
                            <Marker
                                key={`cluster-${i}`}
                                position={{ lat, lng }}
                                label={{
                                    text: pointCount.toString(),
                                    color: 'white'
                                }}
                                icon={{
                                    path: googleMaps?.SymbolPath.CIRCLE,
                                    fillColor: '#333',
                                    fillOpacity: 1,
                                    scale: 20,
                                    strokeColor: '#333',
                                    strokeWeight: 1
                                }}
                            />
                        );
                    }
                    return null;
                })
                : riders.map((location, i) => (
                    <Marker
                        key={`rider-${location.rider.id}`}
                        position={{
                            lat: location.location.latitude,
                            lng: location.location.longitude
                        }}
                        title={`${location.rider.name} - ${location.status}`}
                    />
                ))
            }
        </GoogleMap>
    );
}

export default LiveMap;

