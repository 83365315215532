import * as qs from "qs";
import { PathLike } from "fs";

const BaseApiConfig = {
  baseURL: window?.REACT_APP_API_URL || "https://apigateway.dev.beepbeep.live/", //process.env.REACT_APP_API_URL,
  timeout: 30 * 1000,
  paramsSerializer: {
    serialize: (params: Record<string, any>) => {
      return qs.stringify(params, { arrayFormat: "brackets" });
    },
  },
  withCredentials: false,
};

export const ApiConfig = {
  ...BaseApiConfig,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export const ApiConfigForUpload = {
  ...BaseApiConfig,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
  },
};
