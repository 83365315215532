import React from 'react'
import { BrowserRouter as Router, Routes as RoutesWrapper, Route, Navigate } from 'react-router-dom'
import Home from 'src/pages/Home'
import NotFound from 'src/pages/NotFound'
import ROUTE_CONSTANTS from './route-constants'
import Login from 'src/pages/auth/Login'
import ForgotPassword from 'src/pages/auth/ForgotPassword'
import ResetPassword from 'src/pages/auth/ResetPassword'
import GeneralLayout from 'src/layouts/GeneralLayout'
import MainLayout from 'src/layouts/DashboardLayout'
import { GlobalProvider } from 'src/global-context/GlobalContext'
import TermsAndConditions from 'src/pages/Home/TermsAndConditions'
import PrivacyPolicy from 'src/pages/Home/PrivacyPolicy'
import AboutUs from 'src/pages/Home/AboutUs'
import DashboardHome from 'src/pages/dashboard/Home'
import Profile from 'src/pages/dashboard/Profile'
import Riders from 'src/pages/dashboard/Riders'
import CreateRider from 'src/pages/dashboard/Riders/Create'
import Groups from 'src/pages/dashboard/Groups'
import CreateGroup from 'src/pages/dashboard/Groups/Create'
import Zones from 'src/pages/dashboard/Zones'
import CreateZone from 'src/pages/dashboard/Zones/Create'
import MZones from 'src/pages/dashboard/maintenance/zones'
import MCreateZone from 'src/pages/dashboard/maintenance/zones/Create'
import MZonesDetails from 'src/pages/dashboard/maintenance/zones/ZonesDetails'
import AttendanceTimeline from 'src/pages/dashboard/AttendanceTimeline'
import AttendanceReport from 'src/pages/dashboard/Reports/AttendanceReport'
import RiderDetails from 'src/pages/dashboard/Riders/RiderDetails'
import ZonesDetails from 'src/pages/dashboard/Zones/ZonesDetails'
import Vehicles from 'src/pages/dashboard/Vehicles'
import CreateVehicle from 'src/pages/dashboard/Vehicles/Create'
import ExtendedLiveMap from 'src/pages/dashboard/ExtendedLiveMap'
import OrderHistory from 'src/pages/dashboard/Orders/History'
import OrdersProvider from 'src/pages/dashboard/Orders/OrdersProvider'
import OrderUpdate from 'src/pages/dashboard/Orders/Update'
import UserManagement from 'src/pages/dashboard/UserManagement'
import ThirdPartiesList from 'src/pages/dashboard/ThirdParties/ThirdPartyList'
import ThirdPartyCreateUpdate from 'src/pages/dashboard/ThirdParties/ThirdPartyCreateUpdate'
import AdminCreateUpdateOrder from 'src/pages/dashboard/Orders/AdminCreateUpdateOrder'
import TargetGroups from '../pages/dashboard/TargetGroups'
import CreateTargetGroup from '../pages/dashboard/TargetGroups/Create'
import TargetGroupDetail from '../pages/dashboard/TargetGroups/Details'
import RiderOrdersReport from 'src/pages/dashboard/Reports/RiderOrdersReport'
import Messages from 'src/pages/dashboard/Messages'
import DownloadApp from '../pages/Home/DownloadApp'
import MaintenanceDriversList from '../pages/dashboard/maintenance/maintenance-drivers'
import MaintenanceDriverDetails from '../pages/dashboard/maintenance/maintenance-drivers/Details'
import CreateMaintenanceDriver from '../pages/dashboard/maintenance/maintenance-drivers/Create'
import MaintenanceTaskList from '../pages/dashboard/maintenance/tasks'
import MaintenanceInvoicesList from '../pages/dashboard/maintenance/invoices'
import AmericanaOrdersReport from '../pages/dashboard/Reports/AmericanaOrdersReport'
import RidersWorkScheduleReport from '../pages/dashboard/Reports/RidersWorkScheduleReport'
import RestaurantFeedback from 'src/pages/dashboard/Feedback'
import FeedbackDetails from 'src/pages/dashboard/Feedback/FeedbackDetails'
// import Test from "../pages/Test";

const Routes: React.FC = () => {
  return (
    <Router basename={ROUTE_CONSTANTS.ROOT.ABSOLUTE}>
      <GlobalProvider>
        <RoutesWrapper>
          <Route path={ROUTE_CONSTANTS.ROOT.ABSOLUTE} element={<Home />} />
          <Route path={ROUTE_CONSTANTS.ABOUT_US.ABSOLUTE} element={<AboutUs />} />
          <Route path={ROUTE_CONSTANTS.PRIVACY_POLICY.ABSOLUTE} element={<PrivacyPolicy />} />
          <Route path={ROUTE_CONSTANTS.DOWNLOAD_APP.ABSOLUTE} element={<DownloadApp />} />
          <Route
            path={ROUTE_CONSTANTS.TERMS_AND_CONDITIONS.ABSOLUTE}
            element={<TermsAndConditions />}
          />
          <Route path={ROUTE_CONSTANTS.AUTH.ROOT.RELATIVE} element={<GeneralLayout />}>
            <Route path='' element={<Navigate to={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} />} />
            <Route path={ROUTE_CONSTANTS.AUTH.LOGIN.RELATIVE} element={<Login />} />
            <Route
              path={ROUTE_CONSTANTS.AUTH.FORGOT_PASSWORD.RELATIVE}
              element={<ForgotPassword />}
            />
            <Route
              path={ROUTE_CONSTANTS.AUTH.RESET_PASSWORD.RELATIVE}
              element={<ResetPassword />}
            />
          </Route>
          <Route path={ROUTE_CONSTANTS.DASHBOARD.ROOT.RELATIVE} element={<MainLayout />}>
            <Route path={ROUTE_CONSTANTS.DASHBOARD.HOME.RELATIVE} element={<DashboardHome />} />
            <Route path={ROUTE_CONSTANTS.DASHBOARD.PROFILE.RELATIVE} element={<Profile />} />
            <Route path={ROUTE_CONSTANTS.DASHBOARD.RIDERS.ROOT.RELATIVE} element={<Riders />} />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.RIDERS.DETAILS.RELATIVE}
              element={<RiderDetails />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.RIDERS.CREATE.RELATIVE}
              element={<CreateRider />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.RIDERS.UPDATE.RELATIVE}
              element={<CreateRider />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE.MAINTENANCE_DRIVERS.ROOT.ABSOLUTE}
              element={<MaintenanceDriversList />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE.MAINTENANCE_DRIVERS.DETAILS.ABSOLUTE}
              element={<MaintenanceDriverDetails />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE.MAINTENANCE_DRIVERS.CREATE.ABSOLUTE}
              element={<CreateMaintenanceDriver />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE.MAINTENANCE_DRIVERS.UPDATE.ABSOLUTE}
              element={<CreateMaintenanceDriver />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE.ZONES.ROOT.ABSOLUTE}
              element={<MZones />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE.ZONES.DETAILS.ABSOLUTE}
              element={<MZonesDetails />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE.ZONES.CREATE.ABSOLUTE}
              element={<MCreateZone />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE.ZONES.UPDATE.ABSOLUTE}
              element={<MCreateZone />}
            />
            <Route path={ROUTE_CONSTANTS.DASHBOARD.VEHICLES.ROOT.RELATIVE} element={<Vehicles />} />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.VEHICLES.CREATE.RELATIVE}
              element={<CreateVehicle />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.VEHICLES.UPDATE.RELATIVE}
              element={<CreateVehicle />}
            />
            <Route path={ROUTE_CONSTANTS.DASHBOARD.GROUPS.ROOT.RELATIVE} element={<Groups />} />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.GROUPS.CREATE.RELATIVE}
              element={<CreateGroup />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.GROUPS.UPDATE.RELATIVE}
              element={<CreateGroup />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.TARGET_GROUPS.ROOT.RELATIVE}
              element={<TargetGroups />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.TARGET_GROUPS.CREATE.RELATIVE}
              element={<CreateTargetGroup />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.TARGET_GROUPS.UPDATE.RELATIVE}
              element={<CreateTargetGroup />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.TARGET_GROUPS.DETAILS.RELATIVE}
              element={<TargetGroupDetail />}
            />
            <Route path={ROUTE_CONSTANTS.DASHBOARD.ZONES.ROOT.RELATIVE} element={<Zones />} />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.ZONES.DETAILS.RELATIVE}
              element={<ZonesDetails />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.ZONES.CREATE.RELATIVE}
              element={<CreateZone />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.ZONES.UPDATE.RELATIVE}
              element={<CreateZone />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.ATTENDANCE_TIMELINE.ROOT.RELATIVE}
              element={<AttendanceTimeline />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.ATTENDANCE_TIMELINE.RIDER_TIMELINE.RELATIVE}
              element={<AttendanceTimeline />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.ATTENDANCE_REPORT.ROOT.RELATIVE}
              element={<AttendanceReport />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.LIVE_MAP.ROOT.RELATIVE}
              element={<ExtendedLiveMap />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS.ROOT.RELATIVE}
              element={<OrdersProvider />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS.UPDATE.RELATIVE}
              element={<OrderUpdate />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS_HISTORY.RELATIVE}
              element={<OrderHistory />}
            />
            <Route
              path={
                ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS_HISTORY.CREATE_UPDATE_ORDER_BY_ADMIN
                  .RELATIVE
              }
              element={<AdminCreateUpdateOrder />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.PAGING_ORDERS_HISTORY.UPDATE_ORDER_BY_ADMIN.RELATIVE}
              element={<AdminCreateUpdateOrder />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.USER_MANAGEMENT.RELATIVE}
              element={<UserManagement />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.THIRD_PARTIES.ROOT.RELATIVE}
              element={<ThirdPartiesList />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.THIRD_PARTIES.CREATE.RELATIVE}
              element={<ThirdPartyCreateUpdate />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.THIRD_PARTIES.UPDATE.RELATIVE}
              element={<ThirdPartyCreateUpdate />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.RIDER_ORDERS_REPORT.ROOT.RELATIVE}
              element={<RiderOrdersReport />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.AMERICANA_ORDERS_REPORT.ROOT.RELATIVE}
              element={<AmericanaOrdersReport />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.RIDERS_WORK_SCHEDULE_REPORT.ROOT.RELATIVE}
              element={<RidersWorkScheduleReport />}
            />
            <Route path={ROUTE_CONSTANTS.DASHBOARD.MESSAGES.ROOT.RELATIVE} element={<Messages />} />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.RESTAURANT_FEEDBACK.ROOT.ABSOLUTE}
              element={<RestaurantFeedback />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.RESTAURANT_FEEDBACK.DETAILS.RELATIVE}
              element={<FeedbackDetails />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE.TASKS.ABSOLUTE}
              element={<MaintenanceTaskList />}
            />
            <Route
              path={ROUTE_CONSTANTS.DASHBOARD.MAINTENANCE.INVOICES.ABSOLUTE}
              element={<MaintenanceInvoicesList />}
            />
          </Route>

          <Route path={ROUTE_CONSTANTS.ROOT_STAR.ABSOLUTE} element={<NotFound />} />

          {/*<Route path={"/test"} element={<Test />} />*/}
        </RoutesWrapper>
      </GlobalProvider>
    </Router>
  )
}

export default Routes
