import React, { memo, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";
import { NotFoundResult } from "../NotFoundResult";
import { AgGridReact } from "ag-grid-react";
import {
  InfiniteRowModelModule,
  ModuleRegistry,
  NumberFilterModule,
  PaginationModule,
  ValidationModule,
  TextFilterModule,
  AllCommunityModule,
  IDatasource,
} from "ag-grid-community";
import { themeQuartz } from "ag-grid-community";
import { Pagination } from "../PaginationAGGrid";
import "./AGGrid.css";
import { tryParseJSON } from "../../../utils/JsonHelper";
import { useLocation } from "react-router-dom";
import CustomHeaderCheckbox from "./CustomHeaderCheckbox";

ModuleRegistry.registerModules([
  NumberFilterModule,
  PaginationModule,
  InfiniteRowModelModule,
  ValidationModule,
  TextFilterModule,
  AllCommunityModule,
]);

export interface ColumnsType<T = string> {
  headerName: string;
  field: string;
  key?: string;
  render?: (text: any, record: T) => ReactNode;
  cellRenderer?: (params: any) => ReactNode;
  defaultValue?: string;
  isHidden?: boolean;
  sortable?: boolean;
  resizable?: boolean;
  filter?: boolean;
}

type SortModelType = {
  colId: string;
  sort: string;
}

type FilterModelType = {
  colId: string;
  filter: string;
}

interface TableProps<T> {
  rowData: any[] | undefined;
  onFetchData: ({
    sortModel, filterModel, paginationModel
  }: {sortModel: SortModelType[], filterModel: FilterModelType[], paginationModel: any}) => Promise<{
    items: any[];
    totalCount: number;
  }>;
  columns: ColumnsType<T>[];
  loading: boolean;
  isSelectable?: boolean;
  rowHeight?: number | undefined;
  paginationConfig: {
    passPaginateValues?: (offset: number, size: number) => void;
    entityTitle: string;
  };
  onSortChanged?: (params: { sortModel: SortModelType[] }) => void;
  onFilterChanged?: (params: { filterModel: FilterModelType[] }) => void;
  onPageChange?: (params: { size: number; offset: number }) => void;
  onSelectionChanged?: (selectedRows: any[]) => void;
}

const NoRowsOverlay = () => {
  return <NotFoundResult />;
};

const areEqual = (prevProps: TableProps<any>, nextProps: TableProps<any>) => {
  return (
    prevProps.loading === nextProps.loading &&
      JSON.stringify(prevProps.columns) === JSON.stringify(nextProps.columns)
  );
};

const findArrayDifferences = (arr1, arr2) => {
  // Create a map of the first array objects by colId for easy lookup
  const map1 = arr1.reduce((acc, obj) => {
    acc[obj.colId] = obj.filter;
    return acc;
  }, {});

  // Find objects in arr2 that are either not in arr1 or have different filter values
  const differences = arr2.filter((obj) => {
    // If this colId doesn't exist in arr1, it's a difference
    if (!(obj.colId in map1)) {
      return true;
    }

    // If this colId exists but has a different filter value, it's a difference
    if (map1[obj.colId] !== obj.filter) {
      return true;
    }

    // Otherwise, the object is the same in both arrays
    return false;
  });

  return differences;
};

const CustomLoadingOverlay = () => {
  return (
    <div
      className="ag-overlay-loading-center"
      style={{ backgroundColor: "white", width: "100%", height: "100%" }}
    >
      <div>Loading...</div>
    </div>
  );
};

const TableAGGrid = memo(
    <T extends Record<string, any>>({
    loading,
    columns,
    paginationConfig,
    isSelectable = false,
    onFilterChanged,
    onSortChanged,
    rowHeight = 50,
    onFetchData,
    onPageChange,
    onSelectionChanged
  }: TableProps<T>) => {
      const myTheme = themeQuartz.withParams({
        browserColorScheme: "light",
        headerFontSize: 14,
      });

      const gridRef = useRef<AgGridReact>(null);
      const gridApi = useRef<any>(null);
      const [tableLoading, setTableLoading] = useState<boolean>(false);
      const [dataFetched, setDataFetched] = useState<boolean>(false);
      const initialMount = useRef<boolean>(true);
      const sortApiCalledOnce = useRef<boolean>(false);
      const filterApiCalledOnce = useRef<boolean>(false);
      const location = useLocation();
      const selectionChangedTimer = useRef(null);
      // State for table data management
      const [totalRowCount, setTotalRowCount] = useState<number>(0);

      // Current state trackers
      const currentSortModel = useRef<SortModelType[]>([]);
      const currentFilterModel = useRef<FilterModelType[]>([]);
      const currentPagination = useRef<{size: number, offset: number}>({
        size: 10,
        offset: 0
      });

      // Parse URL params only once on initial render
      const urlParams = useMemo(() => {
        const params = new URLSearchParams(location.search);
        return {
          size: Number(params.get('size')) || 10,
          offset: Number(params.get('offset')) || 0,
          sort: tryParseJSON(params.get('sort'), []),
          filter: tryParseJSON(params.get('filter'), [])
        };
      }, []);

      // Debounced selectionChanged handler
      const handleSelectionChanged = useCallback(() => {
        if (selectionChangedTimer.current) {
          clearTimeout(selectionChangedTimer.current);
        }

        // Start a new debounce timer
        // @ts-ignore
        selectionChangedTimer.current = setTimeout(() => {
          if (gridApi.current && onSelectionChanged) {
            const selectedRows = gridApi.current.getSelectedRows();
            onSelectionChanged(selectedRows);
          }
        }, 100);
      }, [onSelectionChanged]);

      // Clear timer on component unmount
      useEffect(() => {
        return () => {
          if (selectionChangedTimer.current) {
            clearTimeout(selectionChangedTimer.current);
          }
        };
      }, []);

      // Handle grid ready and initial setup
      const handleGridReady = (params) => {
        gridApi.current = params.api;

        let sortApiCalled = false;
        let filterApiCalled = false;
        // Initialize with URL params only on first mount
        if (initialMount.current) {
          // Set initial pagination
          currentPagination.current = {
            size: urlParams.size,
            offset: urlParams.offset
          };

          // Set initial sort if available
          if (urlParams.sort && urlParams.sort.length > 0) {
            currentSortModel.current = urlParams.sort;

            // Apply sort to grid
            const columnState = urlParams.sort.map(sortItem => ({
              colId: sortItem.colId,
              sort: sortItem.sort
            }));

            params.api.applyColumnState({
              state: columnState,
              defaultState: { sort: null },
            });
            sortApiCalledOnce.current = true
            sortApiCalled = true;
          }

          // Set initial filter if available
          if (urlParams.filter && urlParams.filter.length > 0) {
            currentFilterModel.current = urlParams.filter;

            // Apply filter to grid
            const filterModelObj = {};
            urlParams.filter.forEach((filter) => {
              filterModelObj[filter.colId] = {
                type: "text",
                filter: filter.filter,
                filterType: "text",
                conditions: ["contains"],
              };
            });

            params.api.setFilterModel(filterModelObj);
            filterApiCalledOnce.current = true
            filterApiCalled = true;
          }

          // Trigger initial data load by refreshing cache
          setTimeout(() => {
            if (!sortApiCalled || !filterApiCalled) {
              params.api.refreshInfiniteCache();
            }
            initialMount.current = false;
          }, 0);
        }
      };

      // Setup datasource for infinite scroll model
      const dataSource = useMemo((): IDatasource => ({
        getRows: async (params) => {
          const { successCallback, failCallback, filterModel, sortModel } = params;
          const hasFilterChanged = handleFilterChanged(filterModel)
          const hasSortChanged =  handleSortChanged(sortModel)

          if (initialMount.current || (Object.keys(filterModel).length > 1)) {
            return successCallback([], 0)
          }
          try {
            setTableLoading(true);

            const result = await onFetchData({
              sortModel: currentSortModel.current,
              filterModel: currentFilterModel.current,
              paginationModel: (hasSortChanged || hasFilterChanged) ? {
                ...currentPagination.current,
                offset: 0
              } : currentPagination.current
            });

            if (result && result.items) {
              setTotalRowCount(result.totalCount);
              successCallback(result.items, result.items.length);
              setDataFetched(true);
            } else {
              successCallback([], 0);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            failCallback();
          } finally {
            setTableLoading(false);
          }
        }
      }), [onFetchData]);

      // Handle sort changes
      const handleSortChanged = (sortModel: any) => {
        if (!gridApi.current || initialMount.current) return;
        let hasChanged = false;
        const newSortModel = sortModel
          .filter((column) => column.sort)
          .map((column) => ({
            colId: column.colId,
            sort: column.sort,
          }));

        // Only update if sort actually changed
        if (JSON.stringify(currentSortModel.current) !== JSON.stringify(newSortModel)) {
          currentSortModel.current = newSortModel;

          // Notify parent component
          if (onSortChanged) {
            onSortChanged({ sortModel: newSortModel });
          }

          // Refresh data
          if (dataFetched && !initialMount.current) {
            // gridApi.current.refreshInfiniteCache();
          }
          hasChanged = true
        }
        return hasChanged
      };

      // Handle filter changes
      const handleFilterChanged = (filterModelObj: any) => {
        if (!gridApi.current || initialMount.current) return;
        let hasChanged = false;

        const newFilterModel = Object.entries(filterModelObj).map(([key, value]: [string, any]) => ({
          colId: key,
          filter: value.filter,
        }));

        // Only update if filter actually changed
        if (JSON.stringify(currentFilterModel.current) !== JSON.stringify(newFilterModel)) {
          const differences = findArrayDifferences(currentFilterModel.current, newFilterModel);
          const singleFilterData = differences
          currentFilterModel.current = singleFilterData;

          if (newFilterModel.length> 1) {
            // Apply filter to grid
            const filterModelObj = {};
            singleFilterData.forEach((filter) => {
              filterModelObj[filter.colId] = {
                type: "text",
                filter: filter.filter,
                filterType: "text",
                conditions: ["contains"],
              };
            });

            gridApi.current.setFilterModel(filterModelObj);
          }
          // Notify parent component of the changes
          onFilterChanged && onFilterChanged({ filterModel: differences });

          // Refresh data
          if (dataFetched && !initialMount.current) {
            // gridApi.current.refreshInfiniteCache();
          }
          hasChanged = true
        }
        return hasChanged
      };

      // Handle pagination changes
      const handlePaginationChange = useCallback((offset, size) => {
        if (currentPagination.current.offset === offset && currentPagination.current.size === size) {
          return;
        }

        currentPagination.current = { offset, size };

        // Notify parent component
        if (onPageChange) {
          onPageChange({ offset, size });
        }

        // Refresh data if grid is ready
        if (gridApi.current && dataFetched) {
          setTimeout(() => {
            gridApi.current.refreshInfiniteCache();
          }, 0);
        }

        // Update pagination config if callback is provided
        if (paginationConfig.passPaginateValues) {
          paginationConfig.passPaginateValues(offset, size);
        }
      }, [dataFetched, onPageChange, paginationConfig]);

      return (
        <Box className="table_container">
          <div className="ag-theme-alpine" style={{ width: "100%" }}>
            <div style={{ height: "550px", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                loading={loading || tableLoading}
                components={{
                  customHeaderCheckbox: CustomHeaderCheckbox
                }}
                loadingOverlayComponent={CustomLoadingOverlay}
                theme={myTheme}
                columnDefs={columns}
                defaultColDef={{
                  flex: 1,
                  minWidth: 100,
                  resizable: true,
                  filter: "agTextColumnFilter",
                  filterParams: {
                    buttons: ["reset", "apply"],
                    closeOnApply: true,
                    closeOnCancel: true,
                    suppressAndOrCondition: true,
                    suppressOperators: true,
                    alwaysShowBothConditions: false,
                    filterOptions: ["contains"],
                    defaultOption: "contains",
                    suppressFilterButton: true,
                  },
                  sortable: true,
                  cellStyle: {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                suppressMultiSort={true}
                rowHeight={rowHeight}
                onGridReady={handleGridReady}
                onSelectionChanged={handleSelectionChanged}
                rowSelection={
                  isSelectable
                    ? {
                      mode: "multiRow",
                      groupSelects: "descendants"
                    }
                    : undefined
                }
                noRowsOverlayComponent={NoRowsOverlay}
                enableCellTextSelection={true}
                rowModelType="infinite"
                datasource={dataSource}
                maxBlocksInCache={1}
                cacheBlockSize={currentPagination.current.size}
                infiniteInitialRowCount={currentPagination.current.size}
                pagination={false}
                suppressPaginationPanel={true}
                paginationAutoPageSize={false}
              />
            </div>
            <Pagination
              entityTitle={paginationConfig.entityTitle || "Table"}
              loading={loading || tableLoading}
              pageTotalCount={totalRowCount}
              perPageItems={currentPagination.current.size}
              passPaginateValues={handlePaginationChange}
            />
          </div>
        </Box>
      );
    },
    areEqual,
);

export default TableAGGrid;
