import App from "./App";
import "./i18n";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import React, { Suspense } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import SuspenseLoader from "./utils/SuspenseLoader";

const initSentry = () => {
  if (process.env.NODE_ENV !== "development") {
    console.log = () => {};
  }

  if (window.REACT_APP_API_URL) {
    Sentry.init({
      dsn: "https://e8c2bdef17354db89f37414794d1293d@o4504111123988480.ingest.sentry.io/4504201759948800",
      ignoreErrors: [
        // add strings here like:
        // "Network Error",
        "timeout of 30000ms exceeded",
        "Request aborted",
        "Request failed with status code 429",
        "canceled",
        "CanceledError",
        "Request failed with status code 401"
        // 'Request failed with status code 422'
        // 'e.focus is not a functions
      ],
      integrations: [
        Sentry.browserApiErrorsIntegration({
          setTimeout: true,
          setInterval: true,
          requestAnimationFrame: true,
          XMLHttpRequest: true,
          eventTarget: true,
        }),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.breadcrumbsIntegration({
          console: true,
          dom: true,
          fetch: true,
          history: true,
          xhr: true,
        }),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({
          levels: ["error"],
        }),
        Sentry.reportingObserverIntegration(),
        // Sentry.sessionTimingIntegration(),
      ],
      // debug: true, uncomment for debugging your application
      tracesSampleRate: 0.2, // Capture n% of the transactions
      sampleRate: 1.0,
      replaysSessionSampleRate: 0.1, // Sample rate at 10%
      replaysOnErrorSampleRate: 1.0, // 100% sample rate on errors
    });
  }
};

initSentry();

const domNode = document.getElementById("root");
// @ts-ignore
const root = createRoot(domNode);
root.render(
  <Suspense fallback={<SuspenseLoader />}>
    <App />
  </Suspense>,
);
