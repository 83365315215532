import {
  IOngoingOrders,
  IOrderStatusEnum,
  IPendingOrder,
  OrderFlagType,
  TOrderStatus,
} from "src/api/types/orders";
import {
  OrdersDataStoreAction,
  OrdersDataStoreActionKind,
} from "./OrdersDataStore-Actions";

export interface IOrdersDataStoreInitialState {
  orders: IOngoingOrders[];
  pendingOrdersCount: number;
  pendingOrders: IPendingOrder[];
}

export const ordersDataStoreInitialState: IOrdersDataStoreInitialState = {
  orders: [] as IOngoingOrders[],
  pendingOrdersCount: 0,
  pendingOrders: [] as IPendingOrder[],
};

const getNewItemsData = () => {
  return {};
};

export const ordersDataStoreReducer = (
  state: typeof ordersDataStoreInitialState = ordersDataStoreInitialState,
  action: OrdersDataStoreAction<any>
) => {
  const { type, data } = action;
  const orderList = [...state.orders];
  const pendingOrders = [...state.pendingOrders];
  const acceptedOrder = orderList.find((order) => order.id === data.id) as any;

  switch (type) {
    // SET ORDERS
    case OrdersDataStoreActionKind.SET_ORDERS:
      console.log(
        "Pendings On -> SET_ORDERS: ",
        pendingOrders,
        data.pendingCount
      );

      return {
        ...state,
        orders: data.orders,
        pendingOrdersCount: data.pendingCount,
      };
    // ADD_NEW_ORDER
    case OrdersDataStoreActionKind.ADD_NEW_ORDER:
      orderList.unshift(data.newTask);
      return {
        ...state,
        orders: orderList,
        pendingOrders,
        pendingOrdersCount: pendingOrders.length,
      };
    // ACCEPT_ORDER
    // @ts-ignore
    case OrdersDataStoreActionKind.ACCEPT_ORDER:
      if (acceptedOrder) {
        acceptedOrder.acceptedTime = data.acceptedTime;
        acceptedOrder.completedTime = data.completedTime;
        acceptedOrder.driverName = data.driverName;
        acceptedOrder.contactNumber = data.contactNumber;
      }

      return {
        ...state,
        orders: orderList,
        pendingOrders: pendingOrders,
        pendingOrdersCount: pendingOrders.length,
      };
    // UPDATE_ORDER
    case OrdersDataStoreActionKind.UPDATE_ORDER:
      if (acceptedOrder) {
        acceptedOrder.completedTime = data.completedTime;
        acceptedOrder.acceptedTime = data.acceptedTime;
        acceptedOrder.driverName = data.driverName;
        acceptedOrder.contactNumber = data.contactNumber;
        acceptedOrder.cancelledTime = data.cancelledTime;
      }
      return {
        ...state,
        orders: orderList,
        pendingOrders: pendingOrders,
        pendingOrdersCount: pendingOrders.length,
      };

    // SET_PENDING_ORDERS
    case OrdersDataStoreActionKind.SET_PENDING_ORDERS:
      console.log(
        `Pendings On -> SET_PENDING_ORDERS:`,
        data.orders,
        data.count
      );

      return {
        ...state,
        pendingOrders: data.orders,
        pendingOrdersCount: data.count,
      };
  }
};
