const getEntityRoutes = (entity: string, baseRoute: string) => {
  return {
    ROOT: {
      RELATIVE: entity,
      ABSOLUTE: `${baseRoute}/${entity}`
    },
    CREATE: {
      RELATIVE: `${entity}/new`,
      ABSOLUTE: `${baseRoute}/${entity}/new`
    },
    UPDATE: {
      RELATIVE: `${entity}/:id/edit`,
      ABSOLUTE: `${baseRoute}/${entity}/:id/edit`,
      BY_DATA: (id: string) => ({
        RELATIVE: `${entity}/${id}/edit`,
        ABSOLUTE: `${baseRoute}/${entity}/${id}/edit`
      })
    },
    DETAILS: {
      RELATIVE: `${entity}/:id`,
      ABSOLUTE: `${baseRoute}/${entity}/:id`,
      BY_DATA: (id: string) => ({
        RELATIVE: `${entity}/${id}`,
        ABSOLUTE: `${baseRoute}/${entity}/${id}`
      })
    }
  }
}
const ROUTE_CONSTANTS = {
  ROOT: {
    RELATIVE: '/',
    ABSOLUTE: '/'
  },
  ROOT_STAR: {
    RELATIVE: '/*',
    ABSOLUTE: '/*'
  },
  ABOUT_US: {
    RELATIVE: 'about-us',
    ABSOLUTE: '/about-us'
  },
  PRIVACY_POLICY: {
    RELATIVE: 'privacy-policy',
    ABSOLUTE: '/privacy-policy'
  },
  DOWNLOAD_APP: {
    RELATIVE: 'download-app',
    ABSOLUTE: '/download-app'
  },
  TERMS_AND_CONDITIONS: {
    RELATIVE: 'terms-and-conditions',
    ABSOLUTE: '/terms-and-conditions'
  },
  AUTH: {
    ROOT: {
      RELATIVE: 'auth',
      ABSOLUTE: '/auth'
    },
    LOGIN: {
      RELATIVE: 'login',
      ABSOLUTE: '/auth/login'
    },
    FORGOT_PASSWORD: {
      RELATIVE: 'forgot-password',
      ABSOLUTE: '/auth/forgot-password'
    },
    RESET_PASSWORD: {
      RELATIVE: 'reset-password',
      ABSOLUTE: '/auth/reset-password'
    }
  },
  DASHBOARD: {
    ROOT: {
      RELATIVE: 'dashboard',
      ABSOLUTE: '/dashboard'
    },
    HOME: {
      RELATIVE: 'home',
      ABSOLUTE: '/dashboard/home'
    },
    PROFILE: {
      RELATIVE: 'profile',
      ABSOLUTE: '/dashboard/profile'
    },
    RIDERS: getEntityRoutes('riders', '/dashboard'),
    MAINTENANCE: {
      ROOT: {
        RELATIVE: 'maintenance',
        ABSOLUTE: '/dashboard/maintenance'
      },
      MAINTENANCE_DRIVERS: getEntityRoutes('drivers', '/dashboard/maintenance'),
      ZONES: getEntityRoutes('zones', '/dashboard/maintenance'),
      INVOICES: {
        RELATIVE: 'invoice',
        ABSOLUTE: '/dashboard/maintenance/invoices'
      },
      TASKS: {
        RELATIVE: 'tasks',
        ABSOLUTE: '/dashboard/maintenance/tasks'
      }
    },
    GROUPS: getEntityRoutes('groups', '/dashboard'),
    TARGET_GROUPS: getEntityRoutes('target-groups', '/dashboard'),
    ZONES: getEntityRoutes('zones', '/dashboard'),
    VEHICLES: getEntityRoutes('vehicles', '/dashboard'),
    ATTENDANCE_TIMELINE: {
      ROOT: {
        RELATIVE: 'attendance-timeline',
        ABSOLUTE: '/dashboard/attendance-timeline'
      },
      RIDER_TIMELINE: {
        RELATIVE: 'attendance-timeline/:riderId',
        ABSOLUTE: '/dashboard/attendance-timeline/:riderId',
        BY_DATA: (riderId: string) => ({
          RELATIVE: `attendance-timeline/${riderId}`,
          ABSOLUTE: `/dashboard/attendance-timeline/${riderId}`
        })
      }
    },
    ATTENDANCE_REPORT: {
      ROOT: {
        RELATIVE: 'attendance-report',
        ABSOLUTE: '/dashboard/attendance-report'
      }
    },
    LIVE_MAP: {
      ROOT: {
        RELATIVE: 'live-map',
        ABSOLUTE: '/dashboard/live-map'
      }
    },
    EXTENDED_LIVE_MAP: {
      ROOT: {
        RELATIVE: 'extended-live-map',
        ABSOLUTE: '/dashboard/extended-live-map'
      }
    },
    PAGING_ORDERS: {
      ROOT: {
        RELATIVE: `orders`,
        ABSOLUTE: `/dashboard/orders`
      },
      UPDATE: {
        RELATIVE: `orders/:id/edit`,
        ABSOLUTE: `/dashboard/orders/:id/edit`,
        BY_DATA: (orderId: string) => ({
          RELATIVE: `orders/${orderId}/edit`,
          ABSOLUTE: `/dashboard/orders/${orderId}/edit`
        })
      }
    },
    PAGING_ORDERS_HISTORY: {
      RELATIVE: `orders-history`,
      ABSOLUTE: `/dashboard/orders-history`,
      CREATE_UPDATE_ORDER_BY_ADMIN: {
        RELATIVE: `orders-history/generate-order-by-admin`,
        ABSOLUTE: `/dashboard/orders-history/generate-order-by-admin`
      },
      UPDATE_ORDER_BY_ADMIN: {
        RELATIVE: `orders-history/:id/update-order-by-admin`,
        ABSOLUTE: `/dashboard/orders-history/:id/update-order-by-admin`,
        BY_DATA: (orderId: string) => ({
          RELATIVE: `orders-history/${orderId}/update-order-by-admin`,
          ABSOLUTE: `/dashboard/orders-history/${orderId}/update-order-by-admin`
        })
      }
    },
    USER_MANAGEMENT: {
      RELATIVE: 'user-management',
      ABSOLUTE: '/dashboard/user-management'
    },
    THIRD_PARTIES: getEntityRoutes('third-parties', '/dashboard'),
    RIDER_ORDERS_REPORT: {
      ROOT: {
        RELATIVE: 'rider-orders-report',
        ABSOLUTE: '/dashboard/rider-orders-report'
      }
    },
    AMERICANA_ORDERS_REPORT: {
      ROOT: {
        RELATIVE: 'americana-orders-report',
        ABSOLUTE: '/dashboard/americana-orders-report'
      }
    },
    RIDERS_WORK_SCHEDULE_REPORT: {
      ROOT: {
        RELATIVE: 'riders-work-schedule-report',
        ABSOLUTE: '/dashboard/riders-work-schedule-report'
      }
    },
    MESSAGES: {
      ROOT: {
        RELATIVE: 'notifications',
        ABSOLUTE: '/dashboard/notifications'
      }
    },
    RESTAURANT_FEEDBACK: getEntityRoutes('restaurant-feedback', '/dashboard')
  }
}

export default ROUTE_CONSTANTS
