import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ContentViewBody, ContentViewHeader } from 'src/components/app/ContentView'
import { useTranslation } from 'react-i18next'
import { useTitle } from 'src/hooks/useTitle'
import { BrowserBack } from 'src/components/app/BrowserBack'
import SPSpinner from 'src/components/app/SPSpinner'
import { useAbortController } from 'src/hooks/useAbortController'
import { useAsync } from 'src/hooks/useAsync'
import { Box, Flex, Grid, Text, VStack, HStack, Image } from '@chakra-ui/react'
import { mdiStar } from '@mdi/js'
import Icon from '@mdi/react'
import { panelFeedbackService } from 'src/api/services/feedback'
import { IFeedbackDetailsPayload } from 'src/api/types/feedback'
import NoImageSvg from './components/no-image-svg'

const FeedbackDetails: React.FC = () => {
  const { t } = useTranslation()
  useTitle(t('pageTitles.feedbackDetails'))

  const params = useParams<{ id: string }>()
  const { getAbortSignal } = useAbortController()

  const {
    run: getFeedbackDetails,
    isLoading,
    data: feedbackDetails,
    error
  } = useAsync<IFeedbackDetailsPayload, any>(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await panelFeedbackService.get(
          params.id!,
          getAbortSignal('fetchFeedbackDetails').signal
        )
        resolve(response.data.data as IFeedbackDetailsPayload)
      } catch (e) {
        reject(e)
      }
    })
  })

  useEffect(() => {
    if (params.id) {
      getFeedbackDetails({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  if (isLoading) {
    return (
      <Flex minH='calc(100vh - 120px)' justify='center' align='center'>
        <SPSpinner size='xl' thickness='4px' speed='0.65s' color='blue.500' emptyColor='gray.200' />
      </Flex>
    )
  }

  if (error) {
    return (
      <Flex minH='calc(100vh - 120px)' justify='center' align='center'>
        <Text color='red.500'>Error loading feedback details. Please try again.</Text>
      </Flex>
    )
  }

  if (!feedbackDetails) {
    return (
      <Flex minH='calc(100vh - 120px)' justify='center' align='center'>
        <Text>No feedback details found.</Text>
      </Flex>
    )
  }

  return (
    <Flex minH='calc(100vh - 120px)' p={4} flexDir='column' gap={10}>
      <ContentViewHeader>
        <Box display='flex'>
          <Text as='h3' display='inline-block' fontSize='1.4em'>
            {t('titles.feedbackDetails')}
          </Text>
          <BrowserBack />
        </Box>
      </ContentViewHeader>
      <ContentViewBody>
        <Box w='full' maxW='5xl' bg={'white'} rounded='xl' boxShadow='sm' p={{ base: 6, md: 8 }}>
          <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={10}>
            <VStack align='stretch' spacing={10}>
              {/* Rider Information */}
              <VStack align='stretch' spacing={10}>
                <Flex>
                  <Text fontSize={16} fontWeight='bold' w='40' color={'#4D4D4D'}>
                    Rider Employee Id:
                  </Text>
                  <Text fontSize={14} color={'#999999'}>
                    {feedbackDetails.rider.employeeId}
                  </Text>
                </Flex>

                <Flex>
                  <Text fontSize={16} fontWeight='bold' w='40' color={'#4D4D4D'}>
                    Rider Name:
                  </Text>
                  <Text fontSize={14} color={'#999999'}>
                    {feedbackDetails.rider.firstName} {feedbackDetails.rider.lastName}
                  </Text>
                </Flex>

                <Flex>
                  <Text fontSize={16} fontWeight='bold' w='40' color={'#4D4D4D'}>
                    Zone Name:
                  </Text>
                  <Text fontSize={14} color={'#999999'}>
                    {feedbackDetails?.zoneName}
                  </Text>
                </Flex>

                <Flex>
                  <Text fontSize={16} fontWeight='bold' w='40' color={'#4D4D4D'}>
                    Reported by:
                  </Text>
                  <Text fontSize={14} color={'#999999'}>
                    {feedbackDetails.zoneAgent?.employeeId}
                  </Text>
                </Flex>
              </VStack>

              {/* Rating */}
              <VStack align='stretch' spacing={2}>
                <Text fontSize={16} fontWeight='bold' color={'#4D4D4D'}>
                  Rate:
                </Text>
                <HStack spacing={8}>
                  {[1, 2, 3, 4, 5].map(star => (
                    <VStack key={star} align='center'>
                      <Icon
                        path={mdiStar}
                        color={star <= (feedbackDetails.score ?? 0) ? '#F2C94C' : '#E0E0E0'}
                        size={'32px'}
                      />
                      <Text fontSize='sm' mt={1}>
                        {star === 1
                          ? 'Poor'
                          : star === 2
                            ? 'Fair'
                            : star === 3
                              ? 'Good'
                              : star === 4
                                ? 'Very Good'
                                : 'Excellent'}
                      </Text>
                    </VStack>
                  ))}
                </HStack>
              </VStack>

              {/* Good Points */}
              <VStack align='stretch' spacing={3}>
                <Text fontSize={16} fontWeight='bold' color={'#4D4D4D'}>
                  Good Points:
                </Text>
                <Flex flexWrap='wrap' gap={2}>
                  {feedbackDetails.feedbackItems.goodOptions.map(point => (
                    <Box
                      key={point.id}
                      h='36px'
                      w='140px'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      borderRadius='12px'
                      borderColor={'#E4E4E7'}
                      borderWidth={'1px'}
                      fontSize={'12px'}
                      fontWeight={'500'}
                      color={'black'}
                    >
                      {point.titleEn}
                    </Box>
                  ))}
                </Flex>
              </VStack>

              {/* Bad Points */}
              <VStack align='stretch' spacing={3}>
                <Text fontSize={16} fontWeight='bold' color={'#4D4D4D'}>
                  Bad Points:
                </Text>
                <Flex flexWrap='wrap' gap={2}>
                  {feedbackDetails.feedbackItems.badOptions.map(point => (
                    <Box
                      key={point.id}
                      h='36px'
                      w='140px'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      borderRadius='12px'
                      borderColor={'#E4E4E7'}
                      borderWidth={'1px'}
                      fontSize={'12px'}
                      fontWeight={'500'}
                      color={'black'}
                    >
                      {point.titleEn}
                    </Box>
                  ))}
                </Flex>
              </VStack>

              {/* Additional Feedback */}
              <VStack align='stretch' spacing={3}>
                <Text fontSize={16} fontWeight='bold' color={'#4D4D4D'}>
                  Additional feedback:
                </Text>
                <Text fontSize={14} color={'#999999'}>
                  {feedbackDetails.description}
                </Text>
              </VStack>
            </VStack>

            {/* Image Section */}
            <Flex alignItems='center' justifyContent='center'>
              {feedbackDetails.imageUrl ? (
                <Image
                  src={feedbackDetails.imageUrl}
                  alt='Feedback Image'
                  rounded='lg'
                  h={'100%'}
                  w={'100%'}
                  objectFit='cover'
                />
              ) : (
                <Box
                  w='full'
                  h='100%'
                  bg='#F2F2F2'
                  rounded='lg'
                  display='flex'
                  flexDir='column'
                  alignItems='center'
                  justifyContent='center'
                  p={6}
                  position='relative'
                >
                  <VStack mt='4' textAlign='center'>
                    <NoImageSvg />
                    <Text fontWeight='medium' color='gray.800'>
                      No Image
                    </Text>
                    <Text fontSize='sm' color='gray.500'>
                      There is no uploaded image for this feedback
                    </Text>
                  </VStack>
                </Box>
              )}
            </Flex>
          </Grid>
        </Box>
      </ContentViewBody>
    </Flex>
  )
}

export default FeedbackDetails
