import { DEFAULT_COUNT } from "src/constants/api-default-values";
import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse } from "../types/axios-response";
import {
  INewRiderData,
  IRiderListItem,
  IRidersListPayload,
  RiderSorts,
  TUniqueFields,
} from "../types/riders";
import { getListQuerySerialized } from "../../utils/listQuerySerializer";

export const panelRidersService = {
  getAll(
    offset: number,
    count: number,
    sort: RiderSorts,
    abortSignal: AbortSignal,
    searchQuery: string = "",
    status: string = "",
    riderId?: IRiderListItem["id"],
  ) {
    let keyword = searchQuery ? `&keyword=${searchQuery}` : "";
    let searchedStatus = status ? `&WorkingStatusId=${status}` : "";
    let queryRiderId = riderId ? `&AttachRiderId=${riderId}` : "";
    return AxiosInstance.get<IAxiosResponse<IRidersListPayload>>(
      `${API_ROUTES.RIDERS.ROOT}?count=${count}&offset=${offset}&sort=${sort}${keyword}${queryRiderId}${searchedStatus}`,
      { signal: abortSignal },
    );
  },
  getTableList({
    offset,
    count,
    sort = [],
    searchQuery = '',
    filter = [],
    abortSignal,
    status = '',
    riderId
  }: {
    offset: number
    count: number
    sort: {
      colId: string
      sort: string | 'asc' | 'desc'
    }[]
    searchQuery: string
    filter: {
      colId: string
      filter: string
    }[]
    abortSignal: AbortSignal,
    status: string,
    riderId?: IRiderListItem["id"]
  }) {
    const listAdditionalQuery = getListQuerySerialized({
      sort, filter, searchQuery
    })
    let searchedStatus = status ? `&filter_workingStatus=${status}` : "";
    return AxiosInstance.get<IAxiosResponse<IRidersListPayload>>(
      `${API_ROUTES.RIDERS.ROOT_V2}?count=${count}&offset=${offset}${listAdditionalQuery}${searchedStatus}`,
      { signal: abortSignal },
    );
  },
  create(data: INewRiderData) {
    return AxiosInstance.post<IAxiosResponse<{}>>(API_ROUTES.RIDERS.ROOT, data);
  },
  get(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IRiderListItem>>(
      API_ROUTES.RIDERS.GET_DETAILS(id),
      { signal: abortSignal },
    );
  },
  update(id: string, data: INewRiderData) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.RIDERS.GET_DETAILS(id),
      data,
    );
  },
  checkUniqueFieldDublication(value: string, field: TUniqueFields) {
    return AxiosInstance.get<IAxiosResponse<IRidersListPayload>>(
      `${API_ROUTES.RIDERS.ROOT}?count=${DEFAULT_COUNT}&${field}=${value.trim().toLowerCase()}`,
    );
  },
  changePassword(id: string, rawPassword: string) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.RIDERS.CHANGE_PASSWORD(id),
      { rawPassword: rawPassword },
    );
  },
  toDisabledRider(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.RIDERS.TO_DISABLED_RIDER(id),
      { signal: abortSignal },
    );
  },
  toEnabledRider(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.RIDERS.TO_ENABLED_RIDER(id),
      { signal: abortSignal },
    );
  },
  remove(id: string) {
    return AxiosInstance.delete<IAxiosResponse<{}>>(
      API_ROUTES.RIDERS.REMOVE(id),
    );
  },
  removeBulk(riderIds: [string]) {
    let finalQuery = 'RiderIds='
    riderIds.forEach((riderId, index) => {
      if (index === 0) {
        finalQuery += `${riderId}`;
        return
      }
      finalQuery += `&RiderIds=${riderId}`;
    })
    return AxiosInstance.delete<IAxiosResponse<{}>>(
      API_ROUTES.RIDERS.REMOVE_BULK(finalQuery),
    );
  },
  updateWorkingStatus(selectedRiders: [string], currentShiftStatus: string, nextShiftStatus: string) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.RIDERS.UPDATE_WORKING_STATUS,
      {
        riderIds: selectedRiders,
        currentWorkingStatusId: currentShiftStatus,
        nextWorkingStatusId: nextShiftStatus,
      }
    );
  },
  updateTargetGroup(selectedRiders: [string], targetGroupId: string) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.RIDERS.UPDATE_TARGET_GROUPS,
      {
        riderIds: selectedRiders,
        targetGroupId
      }
    );
  },
  disableRiders(selectedRiders: [string]) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.RIDERS.DISABLE_RIDERS,
      {
        riderIds: selectedRiders
      }
    );
  },
};
