import TaskDataProvider from "src/pages/dashboard/maintenance/tasks/TasksDataContext";
import { OnGoingTasks } from "src/pages/dashboard/maintenance/tasks/OnGoing";
import { memo } from "react";

const MaintenanceTaskList = memo(() => {
  return (
    <TaskDataProvider>
      <OnGoingTasks />
    </TaskDataProvider>
  );
});

export default MaintenanceTaskList;
