import { Box, Input } from "@chakra-ui/react";
import React, {memo, useEffect, useState} from "react";
import HorizontalLoading from "src/components/app/HorizontalLoading";
import { panelZonesService } from "../../../../api/services/zones";
import { ZoneSorts } from "../../../../api/types/zones";
import ZonesSelect from "./index";
import { debounce } from 'lodash';

interface ZoneSelectProps {
  onZoneSelected: (zones: any[]) => void;
  onAllSelected: (value: boolean) => void;
  allSelectedCount: number
}

const ZonesSelectContainer = memo(({ onZoneSelected, onAllSelected, allSelectedCount }: ZoneSelectProps) => {
  const [loadingZoneRiders, setLoadingZoneRiders] = useState(false);
  const DEFAULT_SELECT_COUNT = 20;
  const DEFAULT_SELECT_OFFSET = 0;
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [refreshFetch, setRefreshFetch] = useState(0);
  const [zonesList, setZonesList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentOffset, setCurrentOffset] = useState<number>(
      DEFAULT_SELECT_OFFSET
  );
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [searchDisable, setSearchDisable] = useState<boolean>(false);
  const [selectLoading, setSelectLoading] = useState<boolean>(false);
  const abortControllerRef = React.useRef<AbortController | null>(null);
  const onPageEnded = () => {
    if (!selectLoading && hasMore) {
      fetchZonesList(DEFAULT_SELECT_COUNT);
    }
  };

  const onItemsChanged = (data: any[]) => {
    setZonesList(data);
  };

  const debouncedSearch = React.useMemo(
      () =>
          debounce(() => {
            setRefreshFetch(val => val+1)
          }, 500),
      []
  );


  const onSearchQueryChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    debouncedSearch();
  };

  const fetchZonesList = async (offset?: number, resetOffset?: boolean) => {
    setSelectLoading(true);

    // Cancel previous request if exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create new AbortController for this request
    abortControllerRef.current = new AbortController();

    try {
      const offsetCount = resetOffset ? DEFAULT_SELECT_OFFSET : (offset ? currentOffset + offset : currentOffset);
      const res = await panelZonesService.getAll(
          offsetCount,
          DEFAULT_SELECT_COUNT,
          [{
            colId: 'nameEn',
            sort: 'asc'
          }],
          abortControllerRef.current.signal,
          searchQuery
      );

      const zonesListMap = res.data.data.items.map((item) => ({
        id: item.id,
        text: item.nameEn,
        searchableFields: { nameEn: item.nameEn.trim() },
      }));

      setHasMore(zonesListMap.length === DEFAULT_SELECT_COUNT);
      setTotalCount(res.data.data.totalCount || 0);
      setZonesList((prevList) => [...prevList, ...zonesListMap]);
      setCurrentOffset(offsetCount);
    } catch (error: any) {
      // Only log error if it's not a cancellation
      if (error.message !== 'canceled') {
        console.error(error);
      }
    } finally {
      setSelectLoading(false);
    }
  };

  useEffect(() => {
    setZonesList([]);
    setCurrentOffset(0)
    fetchZonesList(0, true);

    return () => {
      // Cleanup: abort any pending request when component unmounts
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [refreshFetch]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, []);

  return (
      <Box cursor={loadingZoneRiders ? "wait" : "default"}>
        <Box mb="4">
          <Input
              type="search"
              placeholder="Search by name"
              size="lg"
              value={searchQuery}
              disabled={searchDisable}
              onChange={onSearchQueryChanged}
          />
        </Box>
        {loadingZoneRiders && (
            <Box p="4" textAlign="center">
              <HorizontalLoading />
            </Box>
        )}
        <ZonesSelect
            items={zonesList}
            totalCount={totalCount}
            onChange={onItemsChanged}
            onSelected={(data: any) => onZoneSelected(data)}
            disabled={loadingZoneRiders}
            hasMore={hasMore}
            loading={selectLoading}
            onScrollToEnd={onPageEnded}
            allSelectedCount={allSelectedCount}
            onAllSelected={(value) => {
              setSearchDisable(value)
              onAllSelected && onAllSelected(value)
            }}
        />
      </Box>
  );
})

export default ZonesSelectContainer;
