import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "src/api/index";
import { IAxiosResponse } from "src/api/types/axios-response";
import { IRidersListPayload, RiderSorts } from "src/api/types/riders";
import { DEFAULT_COUNT } from "src/constants/api-default-values";
import { number } from "yup";

export const panelZoneAssigneeService = {
  getZones(zoneId: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IRidersListPayload>>(
      `${API_ROUTES.MAINTENANCE_DRIVERS.ZONES.GET_CURRENT_ZONES(
        zoneId,
      )}?count=${DEFAULT_COUNT}`,
      { signal: abortSignal },
    );
  },
  deleteZones(zoneId: string, zoneIds: string[]) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      `${API_ROUTES.MAINTENANCE_DRIVERS.ZONES.UNASSIGN_ZONE(zoneId)}`,
      {
        zonesId: zoneIds,
      },
    );
  },
  addZones(zoneId: string, zonesId: string[]) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      `${API_ROUTES.MAINTENANCE_DRIVERS.ZONES.ASSIGN_ZONE(zoneId)}`,
      {
        zonesId,
      },
    );
  },
  getNotOwnedZones(
    zoneId: string,
    onlyActive: boolean,
    abortSignal: AbortSignal,
    offset: number = 0,
    keyword?: string,
  ) {
    let url = `${
      API_ROUTES.ZONES.ROOT
    }?count=${50}&onlyActive=${onlyActive}&ExceptZoneId=${zoneId}&offset=${offset}`;
    if (keyword) url += `&keywords=${keyword}`;
    return AxiosInstance.get<IAxiosResponse<IRidersListPayload>>(url, {
      signal: abortSignal,
    });
  },
};
