import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  Select, ModalHeader, ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import "./ChangeTargetGroupModal.css";
import { panelTargetGroupsService } from "../../../../../api/services/target-groups";
interface ChangeTargetGroupModalProps {
  isOpen: boolean;
  closeModal: () => void;
  data: {
    title: string;
    selectedRiders: string[];
    onSave: (targetGroupId: string) => Promise<void>;
    onCancel: () => void;
  };
}

const ChangeTargetGroupModal: React.FC<ChangeTargetGroupModalProps> = ({
  data,
  closeModal,
}) => {
  const [targetGroups, setTargetGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(true);
  // Get target groups/zones on mount
  useEffect(() => {
    const fetchTargetGroups = async () => {
      try {
        const response =  await panelTargetGroupsService.getAll();
        setTargetGroups(response.data.data.items || []);
      } catch (error) {
        console.error("Error fetching target groups:", error);
      }
    };

    fetchTargetGroups();
  }, []);

  const handleSave = async () => {
    if (selectedGroup) {
      setIsLoading(true)
      await data.onSave(selectedGroup);
      setIsLoading(false)
    }
  };

  const selectedRidersCount = data.selectedRiders?.length || 0;

  return (
    <Modal isOpen={modal} onClose={() => { setModal(false) }} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="16px" p={2}>
        <ModalHeader fontSize="xl" fontWeight="500">
          {data.title}
        </ModalHeader>
        <ModalBody>
          <Text mb={6}>
            Please select the new target group for the {selectedRidersCount} selected riders.
          </Text>

          <Box mb={4}>
            <Text fontWeight="medium" mb={2}>
              Target Group
            </Text>
            <Select
              placeholder="Select target group"
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              size="lg"
            >
              {targetGroups.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.name}
                </option>
              ))}
            </Select>
          </Box>
        </ModalBody>

        <ModalFooter display="flex" gap={3}>
          <Button
            size="lg"
            w="100%"
            style={{
              border: "1px solid #B3B3B3",
              borderRadius: "8px",
              backgroundColor: "#FEFEFE",
              color: "#808080",
            }}
            onClick={() => {
              setModal(false)
              data.onCancel && data.onCancel()
            }}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            w="100%"
            isDisabled={!selectedGroup}
            isLoading={isLoading}
            style={{
              borderRadius: "8px",
              backgroundColor: "#FEE4BA",
              color: "#000000",
              boxShadow: "0px 24px 32px 0px #52525214",
            }}
            onClick={handleSave}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangeTargetGroupModal;
