import { IColumns } from "../types/table";
import { Box } from "@chakra-ui/react";
import { toUpperCamelCase } from "./string-functions";
import { ReactNode } from "react";

export function renderUnderscoreIfNull(value?: string): ReactNode {
  return value ? value : "------";
}

export function makeTableFromMeta<T>(metaColumn: IColumns<T>) {
  return {
    title: toUpperCamelCase(metaColumn.title),
    dataIndex: metaColumn.dataIndex,
    key: metaColumn.title.toLowerCase() + `__${Math.random()}`,
    isSortable: metaColumn.isSortable,
    isHidden: metaColumn.isHidden,
    defaultFilteredValue: metaColumn.defaultValue
      ? [metaColumn.defaultValue]
      : undefined,
    render: (value: any): JSX.Element => (
      <Box>{renderUnderscoreIfNull(value)}</Box>
    ),
    cellRenderer: metaColumn.cellRenderer,
  };
}

export function makeSuffixTable<T>(columnName: string) {
  return {
    title: toUpperCamelCase(columnName),
    dataIndex: columnName.toLowerCase(),
    key: columnName.toLowerCase() + `__${Math.random()}`,
    isSortable: false,
    isHidden: false,
    render: (value: any): JSX.Element => (
      <Box>{renderUnderscoreIfNull(value)}</Box>
    ),
  };
}

export function sortColumns<T>(columns: any[], sortOrder: string[]): any[] {
  const columnMap: { [key: string]: any } = {};
  sortOrder = [
    ...sortOrder,
    ...columns
      .map((col) => col.dataIndex)
      .filter((col) => !sortOrder.includes(col as string)),
  ];
  columns.forEach((column) => {
    columnMap[column.dataIndex as string] = column;
  });

  const sortedColumns: any[] = [];
  sortOrder.forEach((dataIndex) => {
    const column = columnMap[dataIndex];
    if (column) {
      sortedColumns.push(column);
    }
  });

  return sortedColumns;
}
