import React from "react";
import { ChakraProvider } from '@chakra-ui/react'
import Routes from "./Routes";
import { theme } from "./theme";
import {ErrorBoundary} from "@sentry/react";

const App: React.FC = () => {
  return (
      <ErrorBoundary>
        <ChakraProvider theme={theme}>
          <Routes />
        </ChakraProvider>
      </ErrorBoundary>
  );
};

export default App;
