import React, { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import DatePicker from "react-datepicker";
// todo: fix ASAP
import "src/pages/dashboard/Reports/AmericanaOrdersReport/react-datepicker.css";
import { API_ROUTES } from "src/constants/api-routes";
import dateHelper from "src/utils/date-helper";
import { BulkUploadBox } from "../../../../components/app/BulkUpload/BulkUploadBox";

const AmericanaOrdersReport: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.americanaOrdersReport"));
  const [formData, setFormData] = useState({
    date: "",
  });

  const onFileUploaded = () => {
    setFormData({
      date: "",
    });
  };

  return (
    <Box className={"upload-box-page"}>
      <Box alignItems="center">
        <Text as="h3" fontSize="3xl">
          {t("titles.americanaOrdersReport")}
        </Text>
      </Box>
      <Box
        className="flex_filters page-container"
        display="flex"
        flexDirection={"column"}
        alignItems="start"
        justifyContent="start"
        gap={"4"}
        mb="4"
        mt="4"
      >
        <Box className="filters_container upload-box-page">
          <Box className="reactDatePicker_container">
            <label>Select Date:</label>
            <DatePicker
              dateFormat="MM/dd/yyyy"
              className="reactDatePicker"
              placeholderText="MM/dd/yyyy"
              maxDate={new Date()}
              {...(formData.date
                ? {
                    selected: new Date(formData.date),
                  }
                : {})}
              onChange={(date: Date | any) => {
                let updatedDate = dateHelper.formatDate(
                  date.toString(),
                  "YYYY-MM-DD",
                );
                setFormData({
                  ...formData,
                  date: updatedDate,
                });
              }}
            />
          </Box>
        </Box>
        <Box>
          <label className={"upload-box-label"}>Upload Americana Report:</label>

          <Box className="upload-box">
            <BulkUploadBox
              data={{
                bulkUploadApiUrl: API_ROUTES.ORDERS.UPLOAD_AMERICANA_ORDERS,
                // title: "Orders Report",
                onFileUploaded,
                maxValidSize: "200-KB",
                canUpload: !!formData.date,
                dataToAttach: [
                  {
                    key: "Date",
                    value: formData.date,
                  },
                ],
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AmericanaOrdersReport;
