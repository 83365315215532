import { API_ROUTES } from "src/constants/api-routes";
import { AxiosInstance } from "../index";
import { IAxiosResponse, IPaginatedResult } from "../types/axios-response";
import {
  INewGroupData,
  IGroupListItem,
  IZonesGroupData,
} from "../types/groups";

export const panelGroupsService = {
  getAll(
    offset: number,
    count: number,
    abortSignal: AbortSignal,
    searchQuery: string = "",
  ) {
    let keyword = searchQuery ? `&keyword=${searchQuery}` : "";
    return AxiosInstance.get<IAxiosResponse<IPaginatedResult<IGroupListItem>>>(
      `${API_ROUTES.GROUPS.ROOT}?count=${count}&offset=${offset}${keyword}`,
      { signal: abortSignal },
    );
  },
  create(data: INewGroupData, abortSignal: AbortSignal) {
    return AxiosInstance.post<IAxiosResponse<{}>>(
      API_ROUTES.GROUPS.ROOT,
      data,
      { signal: abortSignal },
    );
  },
  get(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IGroupListItem>>(
      API_ROUTES.GROUPS.GET_DETAILS(id),
      { signal: abortSignal },
    );
  },
  update(id: string, data: INewGroupData, abortSignal: AbortSignal) {
    return AxiosInstance.put<IAxiosResponse<{}>>(
      API_ROUTES.GROUPS.UPDATE(id),
      data,
      { signal: abortSignal },
    );
  },
  remove(id: string) {
    return AxiosInstance.delete<IAxiosResponse<{}>>(
      API_ROUTES.GROUPS.REMOVE(id),
    );
  },
  getZonesGroup(id: string, abortSignal: AbortSignal) {
    return AxiosInstance.get<IAxiosResponse<IZonesGroupData>>(
      API_ROUTES.GROUPS.GET_ZONES_GROUP(id),
      { signal: abortSignal },
    );
  },
};
