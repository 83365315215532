import { FormControl, FormLabel } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { panelZonesService } from "src/api/services/zones";
import { IZoneListItem, ZoneSorts } from "src/api/types/zones";
import SearchableListDropDown from "src/components/app/SearchableList";
import { DEFAULT_COUNT, DEFAULT_OFFSET } from "src/constants/api-default-values";
import { useExtendedLiveMapState, useExtendedLiveMapDispatch } from "../../ExtendedLiveMapContext";
import { ExtendedLiveMapActionKind } from "../../ExtendedLiveMapStore/ExtendedLiveMap-Actions";
import { DEFAULT_CENTER } from "src/constants/map-data";

const Zones: React.FC = () => {
    const { zones, activeZone } = useExtendedLiveMapState();
    const dispatch = useExtendedLiveMapDispatch();
    const [, setSelectedZone] = useState<string>("");
    const [loadingZones, setLoadingZones] = useState<boolean>(false);
    const [getZoneAbortController] = useState<AbortController>(new AbortController());

    const handleZoneOnChange = (selectedZoneId: string) => {
        setSelectedZone(selectedZoneId);
        const activeZoneIndex = zones.findIndex(z => z.id === selectedZoneId);
        const isAllZoneSelected = selectedZoneId.trim() === "" ? true : false;
        if (selectedZoneId !== activeZone?.id) {
            dispatch({ type: ExtendedLiveMapActionKind.SET_ACTIVE_ZONE, data: { activeZone: zones[activeZoneIndex], loadingRiders: true, allZoneSelected: isAllZoneSelected } });
        }
    }

    const handlePickedItem = () => {
        let pickedZone = {} as IZoneListItem;
        return {
            id: pickedZone?.id!,
            name: pickedZone?.nameEn!
        }
    }

    // ============================= GET ZONES ==============================
    const getZones = useCallback(async () => {
        setLoadingZones(true);
        try {
            const result = await panelZonesService.getAll(DEFAULT_OFFSET, DEFAULT_COUNT, [{
                colId: 'nameEn',
                sort: 'asc'
            }], getZoneAbortController.signal);
            let zonesList = result.data.data.items;
            let cloneAZoneOption = { ...zonesList[0] };
            let allZoneOption: IZoneListItem = { ...cloneAZoneOption, nameEn: "-- ALL ZONES --", id: " ", location: { latitude: DEFAULT_CENTER.lat, longitude: DEFAULT_CENTER.lng } };
            dispatch({ type: ExtendedLiveMapActionKind.SET_ZONES, data: { zones: [allZoneOption, ...zonesList] } });
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingZones(false);
        }
    }, [getZoneAbortController]);

    useEffect(() => {
        getZones();
        return () => {
            getZoneAbortController.abort();
        }
    }, [getZones, getZoneAbortController]);
    // =========================== END GET ZONES ===============================

    return <div className="l-m-zones">
        <FormControl>
            <FormLabel htmlFor="rider" color="gray.600">Zone</FormLabel>
            <SearchableListDropDown
                entity="zone"
                disabled={loadingZones}
                onSelectItem={(item: string) => handleZoneOnChange(item)}
                items={{ zones: zones }}
                pickedItem={handlePickedItem()}
                minWidth="260px" />
        </FormControl>
    </div>
}

export default Zones;
