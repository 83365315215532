import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTitle } from "src/hooks/useTitle";
import OngoingTaskList from "src/pages/dashboard/maintenance/tasks/OngoingTaskList";
import { useTaskDataDispatch } from "../TasksDataContext";
import { OrdersDataStoreActionKind } from "src/pages/dashboard/maintenance/tasks/OrdersDataStore/OrdersDataStore-Actions";
import "./styles.css";
import { useOngoingTasks } from "../useOngoingTasks";

export const OnGoingTasks = memo(() => {
  const { t } = useTranslation();
  useTitle(t("pageTitles.maintenance.task"));

  const dispatch = useTaskDataDispatch();
  // const { pendingOrdersCount } = useOrderDataState();
  const { newTask, acceptedTask, changedTask, cancelledTask, allowToCallAPI } =
    useOngoingTasks();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    if (Object.keys(newTask).length > 0) {
      console.log("New Item---: ", newTask);
      dispatch({
        type: OrdersDataStoreActionKind.ADD_NEW_ORDER,
        data: { newTask },
      });
    }
  }, [newTask]);

  useEffect(() => {
    if (Object.keys(acceptedTask).length > 0) {
      console.log("Accept Item---: ", acceptedTask);
      dispatch({
        type: OrdersDataStoreActionKind.ACCEPT_ORDER,
        data: {
          id: acceptedTask.id,
          date: acceptedTask.date,
          driverName: acceptedTask.driverName,
          contactNumber: acceptedTask.contactNumber,
          acceptedTime: acceptedTask.acceptedTime,
          cancelledTime: acceptedTask.cancelledTime,
        },
      });
    }
  }, [acceptedTask]);

  useEffect(() => {
    if (Object.keys(cancelledTask).length > 0) {
      console.log("cancelled Item ---: ", cancelledTask);
      dispatch({
        type: OrdersDataStoreActionKind.UPDATE_ORDER,
        data: {
          id: cancelledTask.id,
          date: cancelledTask.date,
          driverName: cancelledTask.driverName,
          contactNumber: cancelledTask.contactNumber,
          completedTime: cancelledTask.completedTime,
          acceptedTime: cancelledTask.acceptedTime,
          cancelledTime: cancelledTask.cancelledTime,
        },
      });
    }
  }, [cancelledTask]);

  useEffect(() => {
    if (Object.keys(changedTask).length > 0) {
      console.log("Done Item ---: ", changedTask);
      dispatch({
        type: OrdersDataStoreActionKind.UPDATE_ORDER,
        data: {
          id: changedTask.id,
          date: changedTask.date,
          driverName: changedTask.driverName,
          contactNumber: changedTask.contactNumber,
          completedTime: changedTask.completedTime,
          acceptedTime: changedTask.acceptedTime,
          cancelledTime: changedTask.cancelledTime,
        },
      });
    }
  }, [changedTask]);

  const handlePendingTabStyles = (
    pendingCount: number,
    selectedTab: boolean = false
  ) => {
    let pendingColor = "#000";
    if (pendingCount <= 1) {
      pendingColor = "#00CE52"; // green
    } else if (pendingCount >= 2 && pendingCount <= 4) {
      pendingColor = "#F39905"; // orange
    } else if (pendingCount >= 5) {
      pendingColor = "#C01C23"; //red
    }

    return selectedTab
      ? {
          borderBottom: `2px solid ${pendingColor}`,
          color: pendingColor,
          fontWeight: 600,
        }
      : { color: pendingColor };
  };

  return (
    <Box className="order_main_wrapper">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minHeight: "60px",
        }}
      >
        <Text as="h3" fontSize="3xl">
          {t("titles.maintenance.task")}
        </Text>
      </Box>
      <Box m={3}>
        <OngoingTaskList
          allowToCallAPI={allowToCallAPI}
          tabIsActive={activeTabIndex === 0 ? true : false}
        />
      </Box>
    </Box>
  );
});
